.footer {
    display: flex;
    align-items: center;
    flex-direction: column;
    background-color: $FOOTER_BKGD_COLOR;
    color: $PRIMARY_COLOR;
    font-size: 0.75rem;
    padding: 2.9rem 1rem 5rem;
    font-family: $HEADING_FONT;
    text-align: center; 
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    @media #{$small} {
        padding: 3rem 3rem 5rem;
        align-items: flex-end; 
        justify-content: space-between;
        margin-top: -6rem;
    }

    a {
        color: $PRIMARY_COLOR;
        letter-spacing: 0.025em;
    }
    &-logo{
        margin-bottom: 2.05rem;
        svg{
            width: 90px;
            height: 91px;
            @media #{$small} {
                width: 100px;
                height: 101px;
            }
        }
    }
    &__highlight{
        background: #E2E5E5;
        color: #264344;
        text-transform: uppercase;
        font-family: $HEADING_FONT;
        font-weight: 700;
        font-size: 14px;
        padding: 0.35rem 1rem;
        border-radius: 1px;
        line-height: 1.5;
        letter-spacing: -0.01em!important;
        display: inline-block;
        margin-left: auto;
        @media #{$small} {
            margin-left: 0.5rem;
        }
        &:hover{
            text-decoration: none;
            background: #264344;
            color: #FFF;
        }
    }
}

.footer-menu {
    @media #{$small} {
        display: flex;
        align-items: baseline;
    }
    .menu {
        margin: 0 0 1rem;
        padding: 0;
        text-align: center;

        @media #{$small} {
            display: flex;
        }
    
        li { 
            padding: 0;
            list-style: none;
            margin: .75rem 0;
            @media #{$small} {
                margin-left: 0;
                margin-right: 1rem;
                text-align: right;
            }

            a {
                color: $PRIMARY_COLOR;
                padding: .25rem;
            }
            a:hover{
                color: #5CA0A2;
            }
            &.bold{
                font-weight: 600;
            }
        }
    }
}

.footer-copyright {
    text-align: center;
    font-size: 12px;
    margin-top: 54px;
    letter-spacing: 0.05em;
    @media #{$small} {
        text-align: right;
        margin-top: 24px;
    }
    a {
        display: block;
        font-weight: 700;
        color: $PRIMARY_COLOR;
        letter-spacing: 0.05em;
    }
    a:hover{
        color: #5CA0A2;
    }
}