.post-template,
.post-template-default,
.page-template-default{
    .page__header{
        background: #264344;
        color: white;
        padding: 4rem 0 4.4rem;
        position: relative;
        & > * {
            max-width: 839px;
            margin: 0 auto;
            display: block;
            padding: 0 1rem 0;
            @media #{$xsmall} {
                padding: 0 2rem 0;
            }
            @media #{$medium} {
                padding: 0 2rem 0;
                max-width: 1024px;
            }
        }
    }
    .page__bg {
        width: 100%;
        height: calc(100% + 132px);
        position: absolute;
        background-size: cover;
        top: -132px;
        left: 0;
        background-position: center;
        max-width: 100%;
        opacity: 0.5;
        mix-blend-mode: overlay;
    }
    .page__brow{
        text-transform: uppercase;
        color: #B3BDBD;
        font-family: "urw-din-semi-condensed", sans-serif;
        font-weight: 500;
        line-height: 1.1;
        letter-spacing: 0.13em;
        font-size: 16px;
        margin-bottom: 0.5em;
        position: relative;
    }
    .page__heading{
        text-transform: uppercase;
        font-weight: 800;
        font-size: 32px;
        letter-spacing: -0.01em;
        line-height: 1;
        position: relative;
        //text-indent: -4px;
        @media #{$xxsmall} {
            font-size: 44px;
        }
        @media #{$xsmall} {
            font-size: 54px;
        }
        @media #{$small} {
            font-size: 64px;
        }
        @media #{$medium} {
            font-size: 72px;
        }
        @media #{$large} {
            font-size: 90px;
        }
    }
    .page__lede{
        font-family: "pt-serif", serif;
        margin-top: 1rem;
        line-height: 1.65;
        margin-top: 0.8rem;
        position: relative;
        @media #{$medium} {
            font-size: 18px;  
            padding-right: 10rem;
        }
    }
    .page__main{
        padding: 3rem 1rem 10rem;
        max-width: 839px;
        margin: 0 auto;
        display: block;
        font-family: "pt-serif", serif;
        @media #{$xsmall} {
            padding: 3rem 2rem 10rem;
        }
        & > *:first-child {
            margin-top: 0!important;
        }
        a{
            color: #5ca0a2;
            font-weight: bold;
        }
        a:hover {
            color: #347173;
        }
        p{
            @media #{$xsmall} {
                font-size: 17px;
                line-height: 1.6;
            }
            @media #{$small} {
                font-size: 18px;   
            }
            @media #{$medium} {
                font-size: 19px;
            }
            @media #{$large} {
                font-size: 20px;
            }
        }
        ul{
            padding: 0 0 0 1.25em;
            @media #{$xsmall} {
                font-size: 17px;
                line-height: 1.4;
            }
            @media #{$small} {
                font-size: 18px;   
            }
            @media #{$medium} {
                font-size: 19px;
            }
            @media #{$large} {
                font-size: 20px;
            }
        }
        li{
            margin: 0.6em 0;
        }
        h1,h2,h3{
            font-family: "urw-din-semi-condensed", sans-serif;
            text-transform: uppercase;
            color: #264344;
            margin-top: 1.5em;
            margin-bottom: 0.5em;
            line-height: 1;
        }
        h2{
            @media #{$small} {
                font-size: 36px;
            }
        }
        .wonderplugin-video{
            margin-top: 2rem !important;
            margin-bottom: 2rem !important;
        }
        [id^="attachment_"]{
            width: calc(100% + 2rem)!important;
            margin-left: -1rem;
            margin-top: 2rem;
            margin-bottom: 2rem;
            @media #{$xsmall} {
                width: calc(100% + 4rem)!important;
                margin-left: -2rem;
            }
            @media #{$medium} {
                width: calc(100% + 8rem)!important;
                margin-left: -4rem;
                margin-top: 3rem;
                margin-bottom: 3rem;
            }
        }
        img{
            display: block;
            width: 100%;
            height: auto;
            box-shadow: 0 16px 60px -4px rgba(0, 0, 0, 0.24);
            
        }
        p > img.alignnone{
            margin-top: 2rem;
            margin-bottom: 2rem;
            @media #{$medium} {
                margin-top: 3rem;
                margin-bottom: 4rem;
            }
        }
        p > img.alignright{
            width: auto;
            max-width: 90%;
            margin: 1.5rem auto 1.5rem;
            @media #{$xsmall} {
                float: right;
                max-width: 50%;
                margin: 1rem -1.5rem 1rem 1.5rem;
            }
            @media #{$small} {
                margin: 1rem -2rem 1rem 2rem;
            }
            @media #{$medium} {
                float: right;
                margin: 1rem -4rem 1rem 2rem;
            }
        }
        p > img.aligncenter{
            width: auto;
            max-width: 90%;
            margin: 1.5rem auto 1.5rem;
            @media #{$xsmall} {
                max-width: 80%;
                margin: 2rem auto;
            }
            @media #{$small} {
                margin: 2rem auto;
            }
            @media #{$medium} {
                margin: 3rem auto;
            }
        }
        .no-shadow{
            box-shadow: none!important;
        }
        .wp-caption-text{
            font-style: italic;
            text-align: center;
            font-size: 15px;
            margin-top: 0.5em;
        }
    }
}

.post-template .page__main.grid,
.post-template-default .page__main.grid {
    h1, h2, h3, h4, h5, h6 {
        margin: 0;
    }
} 

.post-template .page__main .anchors,
.post-template-default .page__main .anchors {
    list-style-type: none;
    margin: 0 0 2em;
    padding: 0;
    border-top: 1px solid #D7DCDC;
    @media #{$large} {
        margin: 0 0 0em;
    }
    &__item {
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #D7DCDC;
    }
    &__link {
        padding: 12px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media #{$small} {
            padding: 20px 0;
        }
    }
    &__label {
        flex: 0 0 auto;
        width: calc(100% - 32px);
    }
    &__icon {
        flex: 0 0 auto;
        width: 14px;
        height: 14px;
        margin: 0 0 0.5em;
    }
}

.align-right {
    text-align: right;
    @media #{$small} {
        &--small {
            text-align: right;
        }
    }
}

.align-center {
    text-align: center;
    @media #{$small} {
        &--small {
            text-align: center;
        }
    }
}

.c--olive { color: #669091!important; }
.c--cacti { color: #3F5B5C!important; }
.c--brand { color: #264344!important; }
.c--text  { color: #212529!important; }
.c--gold  { color: #FFD260!important; }
.c--white { color: #ffffff!important; }
.c--slate { color: #6D6D78!important; }

.bg--olive { background-color: #669091!important; }
.bg--cacti { background-color: #3F5B5C!important; }
.bg--brand { background-color: #264344!important; }
.bg--text  { background-color: #212529!important; }
.bg--gold  { background-color: #FFD260!important; }
.bg--white { background-color: #ffffff!important; }
.bg--slate { background-color: #6D6D78!important; }

.toplines {
    list-style-type: none;
    margin: 0!important;
    padding: 0 0 16px 12px!important;
    text-align: center;
    @media #{$medium} {
        text-align: left;
    }
    li {
        margin: 0 auto 12px!important;
        padding: 0!important;
        position: relative;
    }
    sup {
        display: inline-block;
        position: relative;
        text-indent: -0.5em;
        top: -0.25em;
    }
    &__sm,
    &__lg {
        display: block;
    }
    & + p {
        opacity: 0.7;
    }
}

.sms {
    align-self: center;
}

.sources {
    list-style-type: none;
    margin: 0!important;
    padding: 0!important;
    display: flex;
    flex-wrap: wrap;
    li {
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        align-items: flex-start;
        margin: 1em 0 0!important;
        @media #{$large} {
            width: 50%;
        }
        @media #{$xlarge} {
            //width: 33.3%;
        }
    }
    figure {
        width: 36px;
        margin: 0;
        flex: 0 0 auto;
        text-align: center;
        position: relative;
        top: -0.125em;
    }
    span {
        width: calc(100% - 36px);
        padding-right: 2em;
        display: inline-block;
        line-height: 1.25;
    }
}

.big {
    position: relative;
    margin-bottom: 1rem;
    sup {
        display: inline-block;
        position: relative;
        text-indent: -0.5em;
        top: -0.25em;
    }
    h4 {
        margin-top: 100px;
    }
}

.title {
    position: relative;
    padding: 50vh 3.5vw 3vw;
    @media #{$small} {
        padding: 1.5vw 2vw;
    }
    &__bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: right center;
        background-image: url(https://aisle518.com/wp-content/uploads/2020/11/a518-bcs-bus-c.jpg);
    }
    &__hed {
        position: relative;
        z-index: 9;
    }
}

.intro {
    @media #{$small} {
        .anchors,
        &__dek {
            grid-row-start: 1;
        }
    }
}

.browser {
    background: white;
    overflow: hidden;
    max-height: 400px;
    margin: 0 5vw 24px auto;
    max-width: calc(100% - 40px);
    position: relative;
    //padding: 2.5vw;
    top: -20px;
    box-shadow: 0 10px 24px rgba(0,0,0,0.2);
    border-radius: 4px;
    cursor: pointer;
    transition: all 200ms ease-in-out;
    z-index: 9;
    @media #{$small} {
        max-width: 44vw; // 34vw;
        top: -32px;
    }
    @media #{$medium} {
        max-width: 38vw;
        top: -36px;
        margin: 0 auto;
    }
    @media #{$large} {
        max-width: 32vw;
    }
    &:hover {
        box-shadow: 0 6px 14px rgba(0,0,0,0.25);
    }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 20px;
        background-color: #E0E4E4;
    }
    &:after {
        content: "";
        position: absolute;
        top: 6px;
        left: 12px;
        width: 8px;
        height: 8px;
        border-radius: 8px;
        background-color: #BCC7C7;
        box-shadow: 12px 0px 0 #BCC7C7, 24px 0px 0 #BCC7C7;
    }
}

.size {
    &--jumbo {  font-size: 36px!important;  }
    &--h1 {     font-size: 36px!important;  }
    &--h2 {     font-size: 32px!important;  }
    &--h3 {     font-size: 24px!important;  }
    &--h4 {     font-size: 17px!important;  }
    &--h5 {     font-size: 16px!important;  }
    &--h6 {     font-size: 15px!important;  }
    &--small {  font-size: 12px!important;  }
    @media #{$small} {
        &--jumbo {  font-size: 44px!important;  }
        &--h4 {     font-size: 18px!important;  }
        &--h5 {     font-size: 17px!important;  }
        &--h6 {     font-size: 16px!important;  }
    }
    @media #{$medium} {
        &--jumbo {  font-size: 48px!important;  }
        &--h4 {     font-size: 19px!important;  }
        &--h5 {     font-size: 18px!important;  }
        &--small {  font-size: 14px!important;  }
    }
    @media #{$large} {
        &--jumbo {  font-size: 56px!important;  }
        &--h4 {     font-size: 20px!important;  }
        

    }
}

.f {
    &--urw {
        font-family: "urw-din", sans-serif !important;
    }
    &--sans {
        font-family: $HEADING_FONT!important;
    }
    &--serif {
        font-family: $COPY_FONT!important;
    }
    &--italic {
        font-style: italic;
    }
    &--caps,
    &--uppercase {
        text-transform: uppercase!important;
    }
    &--loose {
        letter-spacing: 0.05em!important;
    }
    &--looser {
        letter-spacing: 0.1em!important;
    }
    &--tight {
        letter-spacing: -0.025em!important;
    }
    &--400 {
        font-weight: 400;
    }
    &--500 {
        font-weight: 500;
    }
    &--600 {
        font-weight: 600;
    }
    &--900 {
        font-weight: 900;
    }
}

.l {
    &--tighter {
        line-height: 1.1!important;
    }
    &--tight {
        line-height: 1.25!important;
    }
    &--normal {
        line-height: 1.5!important;
    }
    &--loose {
        line-height: 1.625!important;
    }
    &--looser {
        line-height: 1.75!important;
    }
}

.m {
    &-b {
        &__0 { margin-bottom: 0em!important; }
        &__1 { margin-bottom: 1em!important; }
        &__075 { margin-bottom: 0.75em!important; }
        &__05 { margin-bottom: 0.5em!important; }
        &__025 { margin-bottom: 0.25em!important; }
        &__2 { margin-bottom: 2em!important; }
        &__3 { margin-bottom: 3em!important; }
        &__4 { margin-bottom: 4em!important; }
        @media #{$small} {
            &__0--small { margin-bottom: 0em!important; }
            &__1--small { margin-bottom: 1em!important; }
            &__075--small { margin-bottom: 0.75em!important; }
            &__05--small { margin-bottom: 0.5em!important; }
            &__025--small { margin-bottom: 0.25em!important; }
            &__2--small { margin-bottom: 2em!important; }
            &__3--small { margin-bottom: 3em!important; }
            &__4--small { margin-bottom: 4em!important; }
        }
        @media #{$medium} {
            &__0--medium { margin-bottom: 0em!important; }
            &__1--medium { margin-bottom: 1em!important; }
            &__075--medium { margin-bottom: 0.75em!important; }
            &__05--medium { margin-bottom: 0.5em!important; }
            &__025--medium { margin-bottom: 0.25em!important; }
            &__2--medium { margin-bottom: 2em!important; }
            &__3--medium { margin-bottom: 3em!important; }
            &__4--medium { margin-bottom: 4em!important; }
        }
        @media #{$large} {
            &__0--large { margin-bottom: 0em!important; }
            &__1--large { margin-bottom: 1em!important; }
            &__075--large { margin-bottom: 0.75em!important; }
            &__05--large { margin-bottom: 0.5em!important; }
            &__025--large { margin-bottom: 0.25em!important; }
            &__2--large { margin-bottom: 2em!important; }
            &__3--large { margin-bottom: 3em!important; }
            &__4--large { margin-bottom: 4em!important; }
        }
    }
    &-t {
        &__0 { margin-top: 0em!important; }
        &__1 { margin-top: 1em!important; }
        &__05 { margin-top: 0.5em!important; }
        &__025 { margin-top: 0.25em!important; }
        &__2 { margin-top: 2em!important; }
        &__3 { margin-top: 3em!important; }
        &__4 { margin-top: 4em!important; }
        @media #{$small} {
            &__0--small { margin-top: 0em!important; }
            &__1--small { margin-top: 1em!important; }
            &__075--small { margin-top: 0.75em!important; }
            &__05--small { margin-top: 0.5em!important; }
            &__025--small { margin-top: 0.25em!important; }
            &__2--small { margin-top: 2em!important; }
            &__3--small { margin-top: 3em!important; }
            &__4--small { margin-top: 4em!important; }
        }
        @media #{$medium} {
            &__0--medium { margin-top: 0em!important; }
            &__1--medium { margin-top: 1em!important; }
            &__075--medium { margin-top: 0.75em!important; }
            &__05--medium { margin-top: 0.5em!important; }
            &__025--medium { margin-top: 0.25em!important; }
            &__2--medium { margin-top: 2em!important; }
            &__3--medium { margin-top: 3em!important; }
            &__4--medium { margin-top: 4em!important; }
        }
        @media #{$large} {
            &__0--large { margin-top: 0em!important; }
            &__1--large { margin-top: 1em!important; }
            &__075--large { margin-top: 0.75em!important; }
            &__05--large { margin-top: 0.5em!important; }
            &__025--large { margin-top: 0.25em!important; }
            &__2--large { margin-top: 2em!important; }
            &__3--large { margin-top: 3em!important; }
            &__4--large { margin-top: 4em!important; }
        }
    }
    &-l {
        &__a { margin-left: auto!important; }
        &__0 { margin-left: 0em!important; }
        &__1 { margin-left: 1em!important; }
        &__05 { margin-left: 0.5em!important; }
        &__025 { margin-left: 0.25em!important; }
        &__2 { margin-left: 2em!important; }
        &__3 { margin-left: 3em!important; }
        &__4 { margin-left: 4em!important; }
        @media #{$small} {
            &__a { margin-left: auto!important; }
            &__0--small { margin-left: 0em!important; }
            &__1--small { margin-left: 1em!important; }
            &__075--small { margin-left: 0.75em!important; }
            &__05--small { margin-left: 0.5em!important; }
            &__025--small { margin-left: 0.25em!important; }
            &__2--small { margin-left: 2em!important; }
            &__3--small { margin-left: 3em!important; }
            &__4--small { margin-left: 4em!important; }
        }
        @media #{$medium} {
            &__a { margin-left: auto!important; }
            &__0--medium { margin-left: 0em!important; }
            &__1--medium { margin-left: 1em!important; }
            &__075--medium { margin-left: 0.75em!important; }
            &__05--medium { margin-left: 0.5em!important; }
            &__025--medium { margin-left: 0.25em!important; }
            &__2--medium { margin-left: 2em!important; }
            &__3--medium { margin-left: 3em!important; }
            &__4--medium { margin-left: 4em!important; }
        }
        @media #{$large} {
            &__a { margin-left: auto!important; }
            &__0--large { margin-left: 0em!important; }
            &__1--large { margin-left: 1em!important; }
            &__075--large { margin-left: 0.75em!important; }
            &__05--large { margin-left: 0.5em!important; }
            &__025--large { margin-left: 0.25em!important; }
            &__2--large { margin-left: 2em!important; }
            &__3--large { margin-left: 3em!important; }
            &__4--large { margin-left: 4em!important; }
        }
    }
    &-r {
        &__a { margin-right: auto!important; }
        &__0 { margin-right: 0em!important; }
        &__1 { margin-right: 1em!important; }
        &__05 { margin-right: 0.5em!important; }
        &__025 { margin-right: 0.25em!important; }
        &__2 { margin-right: 2em!important; }
        &__3 { margin-right: 3em!important; }
        &__4 { margin-right: 4em!important; }
        @media #{$small} {
            &__a { margin-right: auto!important; }
            &__0--small { margin-right: 0em!important; }
            &__1--small { margin-right: 1em!important; }
            &__075--small { margin-right: 0.75em!important; }
            &__05--small { margin-right: 0.5em!important; }
            &__025--small { margin-right: 0.25em!important; }
            &__2--small { margin-right: 2em!important; }
            &__3--small { margin-right: 3em!important; }
            &__4--small { margin-right: 4em!important; }
        }
        @media #{$medium} {
            &__a { margin-right: auto!important; }
            &__0--medium { margin-right: 0em!important; }
            &__1--medium { margin-right: 1em!important; }
            &__075--medium { margin-right: 0.75em!important; }
            &__05--medium { margin-right: 0.5em!important; }
            &__025--medium { margin-right: 0.25em!important; }
            &__2--medium { margin-right: 2em!important; }
            &__3--medium { margin-right: 3em!important; }
            &__4--medium { margin-right: 4em!important; }
        }
        @media #{$large} {
            &__a { margin-right: auto!important; }
            &__0--large { margin-right: 0em!important; }
            &__1--large { margin-right: 1em!important; }
            &__075--large { margin-right: 0.75em!important; }
            &__05--large { margin-right: 0.5em!important; }
            &__025--large { margin-right: 0.25em!important; }
            &__2--large { margin-right: 2em!important; }
            &__3--large { margin-right: 3em!important; }
            &__4--large { margin-right: 4em!important; }
        }
    }
}

.vid {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.vidslider {
    &__vid {
        width: 80vw;
        margin-left: auto;
        margin-right: auto;
        flex: 0 0 auto;
        @media #{$small} {
            &--50 {
                width: 45vw;
                margin-left: 0;
                margin-right: 0;
            }
        }
    }
    &__item {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        justify-content: space-evenly;
    }
    &__hed {
        max-width: 80vw;
        flex: 0 0 auto;
        width: 100%;
        margin-left: auto!important;
        margin-right: auto!important;
    }
    &__sub {
        max-width: 80vw;
        flex: 0 0 auto;
        width: 100%;
        margin-left: auto!important;
        margin-right: auto!important;
    }
}


.sms-gallery {
    margin: 10vmin 0 5vmin;
    .tns-nav {
        margin-left: 5%;
    }
    .tns-controls {
        margin-right: 5%;
    }
}

.ss {
    transform: scale(0.85);
    transition: all 300ms ease-in-out;
    @media #{$medium} {
        transform: scale(0.85);
        transition: all 300ms ease-in-out;
        opacity: 0.3;
        .ss-active & {
            opacity: 1;
            transform: scale(1);
        }
    }
}

.emails {
    position: relative;
    background: #FFFFFF;
    box-shadow: 0px 10px 20px rgba(0,0,0,0.15);
    @media #{$small} {
        //right: 5vw;
    }
    @media #{$medium} {
        right: 0;
    }
    &:after {
        content: "";
        height: 50%;
        right: -5vw;
        width: 5vw;
        position: absolute;
        top: 0;
        background: #EFD187;
        @media #{$small} {
            right: -10vw;
            width: 10vw;
            height: 90%;
            display: none;
        }
    }
    &__pre {
        font-style: italic;
        color: #413B2B;
        background: #EFD187;
        text-align: right;
        position: relative;
        right: -5vw;
        padding: 8px 10vw 20px 5vw;
        @media #{$small} {
            right: 0;
            padding: 12px 0px;
            background: transparent;
        }
        &:before {
            content: "";
            height: 100%;
            left: -5vw;
            width: 5vw;
            position: absolute;
            top: 0;
            background: #EFD187;
            @media #{$small} {
                left: auto;
                width: 10vw;
                right: -10vw;
                display: none;
            }
        }
        
    }
    &__item {
        display: flex;
        padding: 12px 0;
        border-bottom: 1px solid #D7DCDC;
        @media #{$small} {
            padding: 16px 0;
        }
    }
    &__viz {
        position: relative;
        flex: 0 0 auto;
        width: 42px;
        &:before {
            width: 10px;
            height: 10px;
            content: '';
            top: calc(50% - 5px);
            left:  calc(50% - 5px);
            position: absolute;
            border-radius: 5px;
            background: #0070CC;
        }
    }
    &__main {
        flex: 0 0 auto;
        width: calc(100% - 42px);
        @media #{$small} {
            display: flex;
            align-items: baseline;
        }
        @media #{$medium} {
            //display: block;
        }
        @media #{$large} {
            //display: flex;
        }
    }
    &__item {
        cursor: pointer;
        &:hover {
            background: #e0e4e4;
        }
        @media #{$small} {
            display: flex;
        }
        &--read {
            background: #ebeeee;
            .emails__viz {
                opacity: 0;
            }
        }
    }
    &__hed {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        flex: 0 0 auto;
        width: 100%;
        font-size: 14px!important;
        padding-right: 8px;
        @media #{$small} {
            flex: 0 0 auto;
            max-width: calc(30vw - 42px);
        }
        @media #{$medium} {
            max-width: calc(18vw - 42px);
        }
        @media #{$large} {
            //width: 150px;
        }
    }
    &__dek {
        flex: 0 0 auto;
        margin: 0;
        max-width: calc(100vw - 25vw);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 13px!important;
        @media #{$small} {
            max-width: 60vw;
            padding-right: 12px;
            font-size: 14px!important;
        }
        @media #{$medium} {
            //max-width: calc(100vw - 65vw);
            font-size: 13px!important;
            max-width: calc(32vw);
        }
        @media #{$large} {
            font-size: 14px!important;
            max-width: calc(27vw);
            //flex: 0 0 auto;
            //width: calc(100% - 150px);
            //padding-right: 12px;
        }
    }
}

.event{
    &--n:before {   background: linear-gradient( 0deg,      rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--nne:before { background: linear-gradient( 22.5deg,   rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--ne:before {  background: linear-gradient( 45deg,     rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--ene:before { background: linear-gradient( 67.5deg,   rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--e:before {   background: linear-gradient( 90deg,     rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--ese:before { background: linear-gradient( 112.5deg,  rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--se:before {  background: linear-gradient( 135deg,    rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--sse:before { background: linear-gradient( 157.5deg,  rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--s:before {   background: linear-gradient( 180deg,    rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--ssw:before { background: linear-gradient( 202.5deg,  rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--sw:before {  background: linear-gradient( 225deg,    rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--wsw:before { background: linear-gradient( 247.5deg,  rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--w:before {   background: linear-gradient( 270deg,    rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--wnw:before { background: linear-gradient( 292.5deg,  rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--nw:before {  background: linear-gradient( 315deg,    rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &--nnw:before { background: linear-gradient( 337.5deg,  rgba(#264344, 0.9) 15%, rgba(#264344, 0.05) 75% );}
    &__main {
        position: relative;
        z-index: 9;
        h4 {
            letter-spacing: 0.05em;
        }
    }
    &__bg {
        position: absolute;
        width: 100%;
        background-size: cover;
        background-position: center;
        height: 100%;
        top: 0;
        left: 0;
    }
}

.pos {
    &--relative {
        position: relative;
    }
}

.a-s {
    &__center {
        align-self: center;
    }
    &__end {
        align-self: flex-end;
        align-self: end;
    }
}

.tns-outer {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.tns-ovh {
    flex: 0 0 auto;
    width: 100%;
}

.tns-controls {
    margin-right: 5%;
    button {
        border: 0;
        padding: 0;
        margin: 0 0 0 24px;
    }
    svg {
        width: 20px;
        height: 20px;
        fill: #407173;
    }
}

.tns-nav {
    margin-left: 5%;
    button {
        border: 0;
        padding: 0;
        margin: 0 8px 0 0;
        border-radius: 5px;
        width: 10px;
        height: 10px;
        background: rgba( #7D999A, 0.5 );
        &.tns-nav-active {
            background: #407173;
        }
    }
}