/*
Theme Name: Aisle 518 Strategies
Version: 1.0
*/

@import 'reset';
@import 'variables';
@import 'grid';
@import 'slick';
@import 'tinyslider';

html {
  line-height: 1.5;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
  font-family: sans-serif;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

h1, h2, h3, h4 {
  font-family: $HEADING_FONT;
  font-weight: 800;
}

.wf-loading {
  visibility: hidden;
}

@import 'header';
@import 'footer';
@import 'page';
@import 'text-blurb';
@import 'highlight-section';
@import 'image-gallery';
@import 'slider';
@import 'contact';
@import 'staff';
@import 'lightbox';