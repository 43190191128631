.highlight-section {
  position: relative;
  margin-top: -4.75rem;
  &__container {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    z-index: 9;
    @media #{$small} {
      justify-content: space-between;
    }
  }

  &__title--mobile {
    padding-bottom: 50%;
    flex: 0 0 calc(100% - 2rem);
    background-color: ligthen(#264344, 10%);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 210px;
    position: relative;

    @media #{$small} {
      display: none;
    }

    h2 {
      font-size: 54px;
      color: white;
      text-transform: uppercase;
      font-family: $HEADING_FONT;
      position: absolute;
      bottom: -8px;
      right: 3px;
      line-height: 0.9;
      letter-spacing: -0.01em;
      max-width: 190px;
      text-align: right;
    }
  }

  &__title {
    background-color: lighten(#264344, 10%);
    background-size: cover;
    background-position: center;
    position: absolute;
    height: calc(100% + 6rem);
    top: 50%;
    transform: translateY(-50%);
    overflow: hidden;
    display: none;
    box-shadow: 0 20px 60px rgba(0,0,0,0.24);
    @media #{$small} {
      display: block;    
      min-height: 744px;
      position: relative;
      top: auto;
      transform: none;
      width: 35%;
      &:before{
        content: "";
        display: block;
        height: 100%;
        width: 180px;
        position: absolute;
        top: 0;
        right: 0;
        background: linear-gradient(to left,rgba(23,41,42,0.5) 0%, rgba(23,41,42,0) 100%);
      }
    }
    @media #{$medium} {
        width: 26.5%;
    }

    h2 {
      width: 300%;
      font-size: 5rem;
      color: white;
      text-transform: uppercase;
      font-family: $HEADING_FONT;
      float: left;
      margin: 0;
      line-height: 0.8;
      position: absolute;
      transform: rotate(-90deg) translate(100%, 0);
      transform-origin: right bottom;
      bottom: 0;
      right: 0;
      @media #{$small} {
        letter-spacing: -0.03em;
        font-size: 6.4rem;
        bottom: 225px;
        right: -1px;
      }
      @media #{$medium} {
        font-size: 8.4rem;
        bottom: 62px;
      }
    }
  }

  &__highlights {
    display: flex;
    flex-wrap: wrap;
    padding: 2rem;
    @media only screen and (max-width: 374px) {
      padding: 0 1rem;
    }
    @media #{$small} {
      width: 65%;
      padding: 8rem 4rem 5rem;
      max-width: 990px;
      align-content: center;
      margin: 0 0 0 auto;
    }
    @media #{$medium} {
      width: 73.5%;
      padding: 8rem 3rem 5rem;
    }
    @media #{$large} {
      padding: 10rem 3rem 8rem 6rem;
      margin: 0 auto;
    }
  }

  &__highlight {
    width: 100%;
    margin-top: 0.5rem;
    color: $PRIMARY_COLOR;
    @media #{$small} {
      margin-bottom: 0.5rem;
    }
    @media #{$medium} {
      width: calc(50% - 1.5rem);
      margin-top: 0.7rem;
      margin-right: 3rem;
      margin-bottom: 1.5rem;
      &:first-child {
        margin-top: 0;
      }
  
      &:nth-child(2) {
        margin-top: 0;
      }
    }

    &:nth-child(even) {
      margin-right: 0;
    }

    h3 {
      text-transform: uppercase;
      font-family: $HEADING_FONT;
      letter-spacing: .15em;
      font-weight: 500;
      font-size: 1.05rem;
      @media #{$medium} {
        font-size: 1.25rem;
        margin-bottom: 1rem;
      }
    }

    p {
      font-size: 0.875rem;
      font-family: $COPY_FONT;
      @media #{$small} {
        font-size: 1.05rem;
        line-height: 1.6;
      }
      @media #{$medium} {
        font-size: 1.1rem;
        line-height: 1.6;
      }
      @media #{$large} {
        font-size: 1.125rem;
        line-height: 1.8;
      }
    }
  }
}
