.header {
    background-color: $PRIMARY_COLOR;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    flex-wrap: wrap;
    height: 77px;

    @media #{$small} { 
        padding: 1.45rem 1.5rem;
        height: auto;
    }
    @media #{$medium} { 
        padding: 1.45rem 3rem;
    }
    & > * {
        position: relative;
        z-index: 9;
    }
    .header__title {
        h1 {
            margin-bottom: 0;
        }

        img {
            width: 60px;

            @media #{$small} {
                width: auto !important;
            }
        }
    }
    &__highlight{
        background: rgba(255,255,255,0.12);
        color: #F2C766;
        text-transform: uppercase;
        font-family: $HEADING_FONT;
        font-weight: 700;
        font-size: 14px;
        padding: 0.4rem 0.75rem;
        border-radius: 1px;
        margin-left: auto;
        margin-right: 16px;
        @media #{$small} {
            margin-left: 24px;
            margin-right: 0px;
            padding: 0.4rem 1rem;
        }
        &:hover{
            text-decoration: none;
            background: #F2C766;
            color: #264344;
        }
    }
    &__menu-toggle{
        padding: 0;
        flex: 0 0 auto;
        background: transparent;
        outline: none;
        border: 1px solid transparent;
        width: 28px;
        height: 28px;
        display: flex;
        align-content: center;
        justify-content: flex-end;
        position: relative;
        z-index: 9999;
        @media screen and (min-width: 768px) {
          display: none;
        }
      }
    .menu__bar{
    width: calc(100% - 8px);
    height: 2px;
    background: white;
    transition: 0.1s;
    border-radius: 1px;
    &:before,
    &:after{
        content: "";
        position: absolute;
        height: 2px;
        border-radius: 1px;
        background: white;
        transition: 0.175s;
    }
    &:before{
        top: 10px;
        right: 0;
        width: 100%;
    }
    &:after{
        bottom: 4px;
        right: 0;
        width: calc(100% - 4px);
    }
    }
    &__menu-toggle--close{
        .menu__bar{
            background: rgba(0,0,0,0);
            &:before{
            top: 13px;
            right: 0;
            width: 100%;
            transform: rotate(-45deg);
            }
            &:after{
            bottom: 11px;
            right: 0;
            width: 100%;
            transform: rotate(45deg);
            }
        }
    }
}

.menu-toggle {
    border: 0;
    background-color: transparent;
    line-height: 1;
    
    @media #{$small} {
        display: none;
    }

    i {
        font-size: 1.5rem;
        color: white;
        
        &.fa-times {
            display: none;
        }

        &.fa-bars {
            display: block;
        }
    }

    .showMenu & {
        i.fa-times {
            display: block;
        }

        i.fa-bars {
            display: none;
        }
    }
}

.menu-main-menu-container {
    width: 100%;

    @media #{$small} {
        width: auto !important;
        margin-left: auto;
    }
    .header & {
        display: none;

        @media #{$small} {
            display: block !important;
        }
    
        .showMenu & {
            position: fixed;
            top: 77px;
            left: 0;
            width: 100%;
            height: calc(100% - 77px);
            display: block;
            background-color: $PRIMARY_COLOR;
            z-index: 1;
            
            @media #{$small} {
                position: static !important;
            }
        }
    }
}

.header .menu-main-menu-container--close {
    display: flex;
    position: fixed;
    background: rgba(38, 67, 68, 0.95);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 99;
}


#menu-main-menu {
    display: flex;
    font-size: 0.875rem;
    margin: 0;
    padding: 0;

    li {
        list-style: none;
        margin-left: 1.5rem; 
    }

    .bold{
        font-weight: 700;
    }

    a {
        text-transform: uppercase;
        text-decoration: none;
        color: white;
        font-family: $HEADING_FONT;
        letter-spacing: 0.05em;
        font-size: 24px;
        @media #{$small} {
            font-size: 14px;
        }
    }

    a:hover{
        color: #6CBABD;
    }

    .header & {
        flex-direction: column;
        align-items: center;
        margin: auto;

        @media #{$small} {
            margin: 0;
            flex-direction: row;
        }

        li {
            margin-bottom: 1rem;;
            margin-left: 0;

            @media #{$small} {
                margin: 0;
                margin-left: 1.5rem;
            }
        }
    }
}