.text-blurb {
    color: white;
    background-color: $PRIMARY_COLOR;
    padding: 3rem 0 7.1rem;

    @media #{$small} {
        padding: 1.75rem 0 9.5rem;
    }

    @media #{$large} {
        padding: 2.75rem 0 11rem;
    }

    &__container {
        display: flex;
        margin: 0 auto;
        flex-direction: column;
        padding: 0 2rem;
        align-items: baseline;
        @media only screen and (max-width: 374px) {
            padding: 0 1rem;
        }
        @media #{$small} {
            flex-direction: row;
        }
        @media #{$medium} {
            width: 85%;
        }
        @media #{$large} {
            width: 75%;
        }
    }

    &__title {
        font-size: 1rem;
        text-transform: uppercase;
        margin: 0;
        font-family: $HEADING_FONT;
        letter-spacing: 0.125em;
        font-weight: 500;
        color: rgba(255,255,255,0.65);
        line-height: 1;
        position: relative;
        padding-bottom: 1.5rem;
        @media #{$small} {
            text-align: right;
            width: 16.7%; 
            font-size: 1.1rem;
            padding-right: 1rem;
            line-height: 1.2;
        }
        @media #{$large} {
            font-size: 1.25rem;
            padding-right: 2rem;
        }
    }

    &__text {
        font-size: 1.125rem;
        margin: 0;
        font-family: $COPY_FONT;
        line-height: 1.62;
        padding-right: 2.5rem;
        @media #{$small} {
            width: 83.3%;
            font-size: 1.25rem;
        }
        @media #{$large} {
            font-size: 1.375rem;
        }
    }
}