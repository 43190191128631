.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  @extend .noscroll;
  display: none;
  align-items: center;
  z-index: 999;
  &--active {
    display: flex;
  }
  &__progress {
    font-family: $HEADING_FONT;
    font-weight: 500;
    line-height: 1;
  }
  &__bg {
    background: rgba(#111d1e, 0.85);
    position: fixed;
    
    top: 0;
    left: 0;
    width: 100%; 
    height: 100%;
  }
  &__subj {
    font-family: $HEADING_FONT;
    font-size: 18px;
    font-weight: 600;
    padding: 16px 24px 0;
    @media #{$small} {
      font-size: 24px;
    }
  }
  &__menu {
    min-height: 48px;
    display: flex;
    position: relative;
    top: -10px;
  }
  &__frame {
    margin: 0 auto;
    width: 96vw;
    max-width: 700px;
  }
  &__main {
    background: white;
    border-radius: 4px;
    height: 100%;
    max-height: calc(96vh - 48px);
    min-height: calc(96vh - 48px);
    position: relative;
    overflow: auto;
    box-shadow: 0px 0px 40px rgba(0,0,0,0.5);
    img {
      width: 100%;
    }
  }
  &__hdr {
    border-bottom: 1px solid #D7DCDC;
  }
  &__sender {
    padding: 12px 24px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__avatar {
    border-radius: 24px;
    width: 48px;
    height: 48px;
    overflow: hidden;
    border: 1px solid #D7DCDC;
  }
  &__from {
    flex: 0 0 auto;
    font-family: $HEADING_FONT;
    line-height: 1;
    font-weight: 600;
    width: calc(100% - 72px);
  }
  &__icon {
    fill: white;
    width: 18px;
    @include noiOS;
    background: transparent;
    border: 0;
    padding: 0;
  }
  &__next,
  &__prev {
    height: 18px;
    &--mute {
      opacity: 0.5;
    }
  }
  &__fig {
    padding: 1rem;
  }
  &__msg {
    max-width: 650px;
    padding-bottom: 1rem;
    margin: 0 auto;
  }
  &__to {
    font-family: $HEADING_FONT;
    font-weight: 600;
    color: #777;
  }
}