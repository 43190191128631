.row {
  box-sizing: border-box;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.row.reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.col.reverse {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
  -ms-flex-direction: column-reverse;
  flex-direction: column-reverse;
}

.col-xs,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-13,
.col-xs-14,
.col-xs-15,
.col-xs-16,
.col-xs-offset-0,
.col-xs-offset-1,
.col-xs-offset-2,
.col-xs-offset-3,
.col-xs-offset-4,
.col-xs-offset-5,
.col-xs-offset-6,
.col-xs-offset-7,
.col-xs-offset-8,
.col-xs-offset-9,
.col-xs-offset-10,
.col-xs-offset-11,
.col-xs-offset-12,
.col-xs-offset-13,
.col-xs-offset-14,
.col-xs-offset-15,
.col-xs-offset-16 {
  box-sizing: border-box;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
}

.col-xs {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 75%;
}

.col-xs-1 {
  -ms-flex-preferred-size: 6.25%;
  flex-basis: 6.25%;
  max-width: 6.25%;
}

.col-xs-2 {
  -ms-flex-preferred-size: 12.5%;
  flex-basis: 12.5%;
  max-width: 12.5%;
}

.col-xs-3 {
  -ms-flex-preferred-size: 18.56.25%;
  flex-basis: 18.56.25%;
  max-width: 18.56.25%;
}

.col-xs-4 {
  -ms-flex-preferred-size: 25%;
  flex-basis: 25%;
  max-width: 25%;
}

.col-xs-5 {
  -ms-flex-preferred-size: 31.25%;
  flex-basis: 31.25%;
  max-width: 31.25%;
}

.col-xs-6 {
  -ms-flex-preferred-size: 37.5%;
  flex-basis: 37.5%;
  max-width: 37.5%;
}

.col-xs-7 {
  -ms-flex-preferred-size: 43.56.25%;
  flex-basis: 43.56.25%;
  max-width: 43.56.25%;
}

.col-xs-8 {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  max-width: 50%;
}

.col-xs-9 {
  -ms-flex-preferred-size: 56.25%;
  flex-basis: 56.25%;
  max-width: 56.25%;
}

.col-xs-10 {
  -ms-flex-preferred-size: 62.5%;
  flex-basis: 62.5%;
  max-width: 62.5%;
}

.col-xs-11 {
  -ms-flex-preferred-size: 68.75%;
  flex-basis: 68.75%;
  max-width: 68.75%;
}

.col-xs-12 {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
  max-width: 75%;
}

.col-xs-12 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-13 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-14 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-15 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-16 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

.col-xs-offset-1 {
  margin-left: 6.25%;
}

.col-xs-offset-2 {
  margin-left: 12.5%;
}

.col-xs-offset-3 {
  margin-left: 18.75%;
}

.col-xs-offset-4 {
  margin-left: 25%;
}

.col-xs-offset-5 {
  margin-left: 31.25%;
}

.col-xs-offset-6 {
  margin-left: 37.5%;
}

.col-xs-offset-7 {
  margin-left: 43.75%;
}

.col-xs-offset-8 {
  margin-left: 50%;
}

.col-xs-offset-9 {
  margin-left: 56.25%;
}

.col-xs-offset-10 {
  margin-left: 62.5%;
}

.col-xs-offset-11 {
  margin-left: 68.75;
}

.col-xs-offset-12 {
  margin-left: 75%;
}

.col-xs-offset-13 {
  margin-left: 81.25%;
}

.col-xs-offset-14 {
  margin-left: 87.5%;
}

.col-xs-offset-15 {
  margin-left: 93.75;
}

.col-xs-offset-16 {
  margin-left: 100%;
}

.start-xs {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  text-align: start;
}

.center-xs {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.end-xs {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  text-align: end;
}

.top-xs {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.middle-xs {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.bottom-xs {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.around-xs {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.between-xs {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.first-xs {
  -webkit-box-ordinal-group: 0;
  -ms-flex-order: -1;
  order: -1;
}

.last-xs {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 1;
  order: 1;
}

@media only screen and (min-width: 48em) {
  .container {
    width: 49rem;
  }

  .col-sm,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-13,
  .col-sm-14,
  .col-sm-15,
  .col-sm-16 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-sm {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 75%;
  }

  .col-sm-1 {
    -ms-flex-preferred-size: 6.25%;
    flex-basis: 6.25%;
    max-width: 6.25%;
  }

  .col-sm-2 {
    -ms-flex-preferred-size: 12.5%;
    flex-basis: 12.5%;
    max-width: 12.5%;
  }

  .col-sm-3 {
    -ms-flex-preferred-size: 18.56.25%;
    flex-basis: 18.56.25%;
    max-width: 18.56.25%;
  }

  .col-sm-4 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-sm-5 {
    -ms-flex-preferred-size: 31.25%;
    flex-basis: 31.25%;
    max-width: 31.25%;
  }

  .col-sm-6 {
    -ms-flex-preferred-size: 37.5%;
    flex-basis: 37.5%;
    max-width: 37.5%;
  }

  .col-sm-7 {
    -ms-flex-preferred-size: 43.56.25%;
    flex-basis: 43.56.25%;
    max-width: 43.56.25%;
  }

  .col-sm-8 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-sm-9 {
    -ms-flex-preferred-size: 56.25%;
    flex-basis: 56.25%;
    max-width: 56.25%;
  }

  .col-sm-10 {
    -ms-flex-preferred-size: 62.5%;
    flex-basis: 62.5%;
    max-width: 62.5%;
  }

  .col-sm-11 {
    -ms-flex-preferred-size: 68.75%;
    flex-basis: 68.75%;
    max-width: 68.75%;
  }

  .col-sm-12 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-sm-13 {
    -ms-flex-preferred-size: 81.25%;
    flex-basis: 81.25%;
    max-width: 81.25%;
  }

  .col-sm-14 {
    -ms-flex-preferred-size: 87.5%;
    flex-basis: 87.5%;
    max-width: 87.5%;
  }

  .col-sm-15 {
    -ms-flex-preferred-size: 93.75%;
    flex-basis: 93.75%;
    max-width: 93.75%;
  }

  .col-sm-16 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }
  
  .col-sm-offset-1 {
    margin-left: 6.25%;
  }
  
  .col-sm-offset-2 {
    margin-left: 12.5%;
  }
  
  .col-sm-offset-3 {
    margin-left: 18.75%;
  }
  
  .col-sm-offset-4 {
    margin-left: 25%;
  }
  
  .col-sm-offset-5 {
    margin-left: 31.25%;
  }
  
  .col-sm-offset-6 {
    margin-left: 37.5%;
  }
  
  .col-sm-offset-7 {
    margin-left: 43.75%;
  }
  
  .col-sm-offset-8 {
    margin-left: 50%;
  }
  
  .col-sm-offset-9 {
    margin-left: 56.25%;
  }
  
  .col-sm-offset-10 {
    margin-left: 62.5%;
  }
  
  .col-sm-offset-11 {
    margin-left: 68.75;
  }
  
  .col-sm-offset-12 {
    margin-left: 75%;
  }
  
  .col-sm-offset-13 {
    margin-left: 81.25%;
  }
  
  .col-sm-offset-14 {
    margin-left: 87.5%;
  }
  
  .col-sm-offset-15 {
    margin-left: 93.75;
  }
  
  .col-sm-offset-16 {
    margin-left: 100%;
  }

  .start-sm {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-sm {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-sm {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-sm {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .middle-sm {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-sm {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .around-sm {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-sm {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .first-sm {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .last-sm {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media only screen and (min-width: 64em) {
  .container {
    width: 65rem;
  }

  .col-md,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-13,
  .col-md-14,
  .col-md-15,
  .col-md-16 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-md {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 75%;
  }

  .col-md-1 {
    -ms-flex-preferred-size: 6.25%;
    flex-basis: 6.25%;
    max-width: 6.25%;
  }

  .col-md-2 {
    -ms-flex-preferred-size: 12.5%;
    flex-basis: 12.5%;
    max-width: 12.5%;
  }

  .col-md-3 {
    -ms-flex-preferred-size: 18.56.25%;
    flex-basis: 18.56.25%;
    max-width: 18.56.25%;
  }

  .col-md-4 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-md-5 {
    -ms-flex-preferred-size: 31.25%;
    flex-basis: 31.25%;
    max-width: 31.25%;
  }

  .col-md-6 {
    -ms-flex-preferred-size: 37.5%;
    flex-basis: 37.5%;
    max-width: 37.5%;
  }

  .col-md-7 {
    -ms-flex-preferred-size: 43.56.25%;
    flex-basis: 43.56.25%;
    max-width: 43.56.25%;
  }

  .col-md-8 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-md-9 {
    -ms-flex-preferred-size: 56.25%;
    flex-basis: 56.25%;
    max-width: 56.25%;
  }

  .col-md-10 {
    -ms-flex-preferred-size: 62.5%;
    flex-basis: 62.5%;
    max-width: 62.5%;
  }

  .col-md-11 {
    -ms-flex-preferred-size: 68.75%;
    flex-basis: 68.75%;
    max-width: 68.75%;
  }

  .col-md-12 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-md-13 {
    -ms-flex-preferred-size: 81.25%;
    flex-basis: 81.25%;
    max-width: 81.25%;
  }

  .col-md-14 {
    -ms-flex-preferred-size: 87.5%;
    flex-basis: 87.5%;
    max-width: 87.5%;
  }

  .col-md-15 {
    -ms-flex-preferred-size: 93.75%;
    flex-basis: 93.75%;
    max-width: 93.75%;
  }

  .col-md-16 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }
  
  .col-md-offset-1 {
    margin-left: 6.25%;
  }
  
  .col-md-offset-2 {
    margin-left: 12.5%;
  }
  
  .col-md-offset-3 {
    margin-left: 18.75%;
  }
  
  .col-md-offset-4 {
    margin-left: 25%;
  }
  
  .col-md-offset-5 {
    margin-left: 31.25%;
  }
  
  .col-md-offset-6 {
    margin-left: 37.5%;
  }
  
  .col-md-offset-7 {
    margin-left: 43.75%;
  }
  
  .col-md-offset-8 {
    margin-left: 50%;
  }
  
  .col-md-offset-9 {
    margin-left: 56.25%;
  }
  
  .col-md-offset-10 {
    margin-left: 62.5%;
  }
  
  .col-md-offset-11 {
    margin-left: 68.75;
  }
  
  .col-md-offset-12 {
    margin-left: 75%;
  }
  
  .col-md-offset-13 {
    margin-left: 81.25%;
  }
  
  .col-md-offset-14 {
    margin-left: 87.5%;
  }
  
  .col-md-offset-15 {
    margin-left: 93.75;
  }
  
  .col-md-offset-16 {
    margin-left: 100%;
  }

  .start-md {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-md {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-md {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-md {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .middle-md {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-md {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .around-md {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-md {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .first-md {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .last-md {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

@media only screen and (min-width: 75em) {
  .container {
    width: 76rem;
  }

  .col-lg,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-13,
  .col-lg-14,
  .col-lg-15,
  .col-lg-16 {
    box-sizing: border-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }

  .col-lg {
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    max-width: 75%;
  }

  .col-lg-1 {
    -ms-flex-preferred-size: 6.25%;
    flex-basis: 6.25%;
    max-width: 6.25%;
  }

  .col-lg-2 {
    -ms-flex-preferred-size: 12.5%;
    flex-basis: 12.5%;
    max-width: 12.5%;
  }

  .col-lg-3 {
    -ms-flex-preferred-size: 18.56.25%;
    flex-basis: 18.56.25%;
    max-width: 18.56.25%;
  }

  .col-lg-4 {
    -ms-flex-preferred-size: 25%;
    flex-basis: 25%;
    max-width: 25%;
  }

  .col-lg-5 {
    -ms-flex-preferred-size: 31.25%;
    flex-basis: 31.25%;
    max-width: 31.25%;
  }

  .col-lg-6 {
    -ms-flex-preferred-size: 37.5%;
    flex-basis: 37.5%;
    max-width: 37.5%;
  }

  .col-lg-7 {
    -ms-flex-preferred-size: 43.56.25%;
    flex-basis: 43.56.25%;
    max-width: 43.56.25%;
  }

  .col-lg-8 {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
    max-width: 50%;
  }

  .col-lg-9 {
    -ms-flex-preferred-size: 56.25%;
    flex-basis: 56.25%;
    max-width: 56.25%;
  }

  .col-lg-10 {
    -ms-flex-preferred-size: 62.5%;
    flex-basis: 62.5%;
    max-width: 62.5%;
  }

  .col-lg-11 {
    -ms-flex-preferred-size: 68.75%;
    flex-basis: 68.75%;
    max-width: 68.75%;
  }

  .col-lg-12 {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
    max-width: 75%;
  }

  .col-lg-13 {
    -ms-flex-preferred-size: 81.25%;
    flex-basis: 81.25%;
    max-width: 81.25%;
  }

  .col-lg-14 {
    -ms-flex-preferred-size: 87.5%;
    flex-basis: 87.5%;
    max-width: 87.5%;
  }

  .col-lg-15 {
    -ms-flex-preferred-size: 93.75%;
    flex-basis: 93.75%;
    max-width: 93.75%;
  }

  .col-lg-16 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }
  
  .col-lg-offset-1 {
    margin-left: 6.25%;
  }
  
  .col-lg-offset-2 {
    margin-left: 12.5%;
  }
  
  .col-lg-offset-3 {
    margin-left: 18.75%;
  }
  
  .col-lg-offset-4 {
    margin-left: 25%;
  }
  
  .col-lg-offset-5 {
    margin-left: 31.25%;
  }
  
  .col-lg-offset-6 {
    margin-left: 37.5%;
  }
  
  .col-lg-offset-7 {
    margin-left: 43.75%;
  }
  
  .col-lg-offset-8 {
    margin-left: 50%;
  }
  
  .col-lg-offset-9 {
    margin-left: 56.25%;
  }
  
  .col-lg-offset-10 {
    margin-left: 62.5%;
  }
  
  .col-lg-offset-11 {
    margin-left: 68.75;
  }
  
  .col-lg-offset-12 {
    margin-left: 75%;
  }
  
  .col-lg-offset-13 {
    margin-left: 81.25%;
  }
  
  .col-lg-offset-14 {
    margin-left: 87.5%;
  }
  
  .col-lg-offset-15 {
    margin-left: 93.75;
  }
  
  .col-lg-offset-16 {
    margin-left: 100%;
  }

  .start-lg {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    text-align: start;
  }

  .center-lg {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }

  .end-lg {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    text-align: end;
  }

  .top-lg {
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }

  .middle-lg {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .bottom-lg {
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
  }

  .around-lg {
    -ms-flex-pack: distribute;
    justify-content: space-around;
  }

  .between-lg {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }

  .first-lg {
    -webkit-box-ordinal-group: 0;
    -ms-flex-order: -1;
    order: -1;
  }

  .last-lg {
    -webkit-box-ordinal-group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}

.grid {
  display: grid!important;
  max-width: 100%!important;
  padding-left: 0!important;
  padding-right: 0!important;
  grid-template-columns: repeat(20, 1fr);
  @media #{$small} {
    grid-template-columns: repeat(20, 1fr);
  }
  & > * {
    grid-column: 2 / 20;
  }
  & > hr {
    border: 2px solid #eff1f1;
    grid-column: 8 / 14;
    width: 100%;
    margin: 7vmin 0;
    @media #{$small} {
      border: 3px solid #eff1f1;
    }
    @media #{$medium} {
      border: 4px solid #eff1f1;
    }
  }
  // 1
  &__1-2 {  grid-column: 1 / 2; }
  &__1-3 {  grid-column: 1 / 3; }
  &__1-4 {  grid-column: 1 / 4; }
  &__1-5 {  grid-column: 1 / 5; }
  &__1-6 {  grid-column: 1 / 6; }
  &__1-7 {  grid-column: 1 / 7; }
  &__1-8 {  grid-column: 1 / 8; }
  &__1-9 {  grid-column: 1 / 9; }
  &__1-10 { grid-column: 1 / 10; }
  &__1-11 { grid-column: 1 / 11; }
  &__1-12 { grid-column: 1 / 12; }
  &__1-13 { grid-column: 1 / 13; }
  &__1-14 { grid-column: 1 / 14; }
  &__1-15 { grid-column: 1 / 15; }
  &__1-16 { grid-column: 1 / 16; }
  &__1-17 { grid-column: 1 / 17; }
  &__1-18 { grid-column: 1 / 18; }
  &__1-19 { grid-column: 1 / 19; }
  &__1-20 { grid-column: 1 / 20; }
  &__1-21 { grid-column: 1 / 21; }
  // 2
  &__2-3 {    grid-column: 2 / 3; }
  &__2-4 {    grid-column: 2 / 4; }
  &__2-5 {    grid-column: 2 / 5; }
  &__2-6 {    grid-column: 2 / 6; }
  &__2-7 {    grid-column: 2 / 7; }
  &__2-8 {    grid-column: 2 / 8; }
  &__2-9 {    grid-column: 2 / 9; }
  &__2-10 {   grid-column: 2 / 10; }
  &__2-11 {   grid-column: 2 / 11; }
  &__2-12 {   grid-column: 2 / 12; }
  &__2-13 {   grid-column: 2 / 13; }
  &__2-14 {   grid-column: 2 / 14; }
  &__2-15 {   grid-column: 2 / 15; }
  &__2-16 {   grid-column: 2 / 16; }
  &__2-17 {   grid-column: 2 / 17; }
  &__2-18 {   grid-column: 2 / 18; }
  &__2-19 {   grid-column: 2 / 19; }
  &__2-20 {   grid-column: 2 / 20; }
  &__2-21 {   grid-column: 2 / 21; }
  // 3
  &__3-4 {  grid-column: 3 / 4; }
  &__3-5 {  grid-column: 3 / 5; }
  &__3-6 {  grid-column: 3 / 6; }
  &__3-7 {  grid-column: 3 / 7; }
  &__3-8 {  grid-column: 3 / 8; }
  &__3-9 {  grid-column: 3 / 9; }
  &__3-10 { grid-column: 3 / 10; }
  &__3-11 { grid-column: 3 / 11; }
  &__3-12 { grid-column: 3 / 12; }
  &__3-13 { grid-column: 3 / 13; }
  &__3-14 { grid-column: 3 / 14; }
  &__3-15 { grid-column: 3 / 15; }
  &__3-16 { grid-column: 3 / 16; }
  &__3-17 { grid-column: 3 / 17; }
  &__3-18 { grid-column: 3 / 18; }
  &__3-19 { grid-column: 3 / 19; }
  &__3-20 { grid-column: 3 / 20; }
  &__3-21 { grid-column: 3 / 21; }
  // 4
  &__4-5 {  grid-column: 4 / 5; }
  &__4-6 {  grid-column: 4 / 6; }
  &__4-7 {  grid-column: 4 / 7; }
  &__4-8 {  grid-column: 4 / 8; }
  &__4-9 {  grid-column: 4 / 9; }
  &__4-10 { grid-column: 4 / 10; }
  &__4-11 { grid-column: 4 / 11; }
  &__4-12 { grid-column: 4 / 12; }
  &__4-13 { grid-column: 4 / 13; }
  &__4-14 { grid-column: 4 / 14; }
  &__4-15 { grid-column: 4 / 15; }
  &__4-16 { grid-column: 4 / 16; }
  &__4-17 { grid-column: 4 / 17; }
  &__4-18 { grid-column: 4 / 18; }
  &__4-19 { grid-column: 4 / 19; }
  &__4-20 { grid-column: 4 / 20; }
  &__4-21 { grid-column: 4 / 21; }
  // 5
  &__5-6 {  grid-column: 5 / 6; }
  &__5-7 {  grid-column: 5 / 7; }
  &__5-8 {  grid-column: 5 / 8; }
  &__5-9 {  grid-column: 5 / 9; }
  &__5-10 { grid-column: 5 / 10; }
  &__5-11 { grid-column: 5 / 11; }
  &__5-12 { grid-column: 5 / 12; }
  &__5-13 { grid-column: 5 / 13; }
  &__5-14 { grid-column: 5 / 14; }
  &__5-15 { grid-column: 5 / 15; }
  &__5-16 { grid-column: 5 / 16; }
  &__5-17 { grid-column: 5 / 17; }
  &__5-18 { grid-column: 5 / 18; }
  &__5-19 { grid-column: 5 / 19; }
  &__5-20 { grid-column: 5 / 20; }
  &__5-21 { grid-column: 5 / 21; }
  // 6
  &__6-7 {  grid-column: 6 / 7; }
  &__6-8 {  grid-column: 6 / 8; }
  &__6-9 {  grid-column: 6 / 9; }
  &__6-10 { grid-column: 6 / 10; }
  &__6-11 { grid-column: 6 / 11; }
  &__6-12 { grid-column: 6 / 12; }
  &__6-13 { grid-column: 6 / 13; }
  &__6-14 { grid-column: 6 / 14; }
  &__6-15 { grid-column: 6 / 15; }
  &__6-16 { grid-column: 6 / 16; }
  &__6-17 { grid-column: 6 / 17; }
  &__6-18 { grid-column: 6 / 18; }
  &__6-19 { grid-column: 6 / 19; }
  &__6-20 { grid-column: 6 / 20; }
  &__6-21 { grid-column: 6 / 21; }
  // 7
  &__7-8 {  grid-column: 7 / 8; }
  &__7-9 {  grid-column: 7 / 9; }
  &__7-10 { grid-column: 7 / 10; }
  &__7-11 { grid-column: 7 / 11; }
  &__7-12 { grid-column: 7 / 12; }
  &__7-13 { grid-column: 7 / 13; }
  &__7-14 { grid-column: 7 / 14; }
  &__7-15 { grid-column: 7 / 15; }
  &__7-16 { grid-column: 7 / 16; }
  &__7-17 { grid-column: 7 / 17; }
  &__7-18 { grid-column: 7 / 18; }
  &__7-19 { grid-column: 7 / 19; }
  &__7-20 { grid-column: 7 / 20; }
  &__7-21 { grid-column: 7 / 21; }
  // 8
  &__8-9 {  grid-column: 8 / 9; }
  &__8-10 { grid-column: 8 / 10; }
  &__8-11 { grid-column: 8 / 11; }
  &__8-12 { grid-column: 8 / 12; }
  &__8-13 { grid-column: 8 / 13; }
  &__8-14 { grid-column: 8 / 14; }
  &__8-15 { grid-column: 8 / 15; }
  &__8-16 { grid-column: 8 / 16; }
  &__8-17 { grid-column: 8 / 17; }
  &__8-18 { grid-column: 8 / 18; }
  &__8-19 { grid-column: 8 / 19; }
  &__8-20 { grid-column: 8 / 20; }
  &__8-21 { grid-column: 8 / 21; }
  // 9
  &__9-10 { grid-column: 9 / 10; }
  &__9-11 { grid-column: 9 / 11; }
  &__9-12 { grid-column: 9 / 12; }
  &__9-13 { grid-column: 9 / 13; }
  &__9-14 { grid-column: 9 / 14; }
  &__9-15 { grid-column: 9 / 15; }
  &__9-16 { grid-column: 9 / 16; }
  &__9-17 { grid-column: 9 / 17; }
  &__9-18 { grid-column: 9 / 18; }
  &__9-19 { grid-column: 9 / 19; }
  &__9-20 { grid-column: 9 / 20; }
  &__9-21 { grid-column: 9 / 21; }
  // 10
  &__10-11 { grid-column: 10 / 11; }
  &__10-12 { grid-column: 10 / 12; }
  &__10-13 { grid-column: 10 / 13; }
  &__10-14 { grid-column: 10 / 14; }
  &__10-15 { grid-column: 10 / 15; }
  &__10-16 { grid-column: 10 / 16; }
  &__10-17 { grid-column: 10 / 17; }
  &__10-18 { grid-column: 10 / 18; }
  &__10-19 { grid-column: 10 / 19; }
  &__10-20 { grid-column: 10 / 20; }
  &__10-21 { grid-column: 10 / 21; }
  // 11
  &__11-12 { grid-column: 11 / 12; }
  &__11-13 { grid-column: 11 / 13; }
  &__11-14 { grid-column: 11 / 14; }
  &__11-15 { grid-column: 11 / 15; }
  &__11-16 { grid-column: 11 / 16; }
  &__11-17 { grid-column: 11 / 17; }
  &__11-18 { grid-column: 11 / 18; }
  &__11-19 { grid-column: 11 / 19; }
  &__11-20 { grid-column: 11 / 20; }
  &__11-21 { grid-column: 11 / 21; }
  // 12
  &__12-13 { grid-column: 12 / 13; }
  &__12-14 { grid-column: 12 / 14; }
  &__12-15 { grid-column: 12 / 15; }
  &__12-16 { grid-column: 12 / 16; }
  &__12-17 { grid-column: 12 / 17; }
  &__12-18 { grid-column: 12 / 18; }
  &__12-19 { grid-column: 12 / 19; }
  &__12-20 { grid-column: 12 / 20; }
  &__12-21 { grid-column: 12 / 21; }
  // 13
  &__13-14 { grid-column: 13 / 14; }
  &__13-15 { grid-column: 13 / 15; }
  &__13-16 { grid-column: 13 / 16; }
  &__13-17 { grid-column: 13 / 17; }
  &__13-18 { grid-column: 13 / 18; }
  &__13-19 { grid-column: 13 / 19; }
  &__13-20 { grid-column: 13 / 20; }
  &__13-21 { grid-column: 13 / 21; }
  // 14
  &__14-15 { grid-column: 14 / 15; }
  &__14-16 { grid-column: 14 / 16; }
  &__14-17 { grid-column: 14 / 17; }
  &__14-18 { grid-column: 14 / 18; }
  &__14-19 { grid-column: 14 / 19; }
  &__14-20 { grid-column: 14 / 20; }
  &__14-21 { grid-column: 14 / 21; }
  // 15
  &__15-16 { grid-column: 15 / 16; }
  &__15-17 { grid-column: 15 / 17; }
  &__15-18 { grid-column: 15 / 18; }
  &__15-19 { grid-column: 15 / 19; }
  &__15-20 { grid-column: 15 / 20; }
  &__15-21 { grid-column: 15 / 21; }
  // 16
  &__16-17 { grid-column: 16 / 17; }
  &__16-18 { grid-column: 16 / 18; }
  &__16-19 { grid-column: 16 / 19; }
  &__16-20 { grid-column: 16 / 20; }
  &__16-21 { grid-column: 16 / 21; }
  // 17
  &__17-18 { grid-column: 17 / 18; }
  &__17-19 { grid-column: 17 / 19; }
  &__17-20 { grid-column: 17 / 20; }
  &__17-21 { grid-column: 17 / 21; }
  // 18
  &__18-19 { grid-column: 18 / 19; }
  &__18-20 { grid-column: 18 / 20; }
  &__18-21 { grid-column: 18 / 21; }
  // 19
  &__19-20 { grid-column: 19 / 20; }
  &__19-21 { grid-column: 19 / 21; }
  // 20
  &__20-21 { grid-column: 20 / 21; }

  @media #{$small} {
    // 1
    &__1-2--small {  grid-column: 1 / 2; }
    &__1-3--small {  grid-column: 1 / 3; }
    &__1-4--small {  grid-column: 1 / 4; }
    &__1-5--small {  grid-column: 1 / 5; }
    &__1-6--small {  grid-column: 1 / 6; }
    &__1-7--small {  grid-column: 1 / 7; }
    &__1-8--small {  grid-column: 1 / 8; }
    &__1-9--small {  grid-column: 1 / 9; }
    &__1-10--small { grid-column: 1 / 10; }
    &__1-11--small { grid-column: 1 / 11; }
    &__1-12--small { grid-column: 1 / 12; }
    &__1-13--small { grid-column: 1 / 13; }
    &__1-14--small { grid-column: 1 / 14; }
    &__1-15--small { grid-column: 1 / 15; }
    &__1-16--small { grid-column: 1 / 16; }
    &__1-17--small { grid-column: 1 / 17; }
    &__1-18--small { grid-column: 1 / 18; }
    &__1-19--small { grid-column: 1 / 19; }
    &__1-20--small { grid-column: 1 / 20; }
    &__1-21--small { grid-column: 1 / 21; }
    // 2
    &__2-3--small {    grid-column: 2 / 3; }
    &__2-4--small {    grid-column: 2 / 4; }
    &__2-5--small {    grid-column: 2 / 5; }
    &__2-6--small {    grid-column: 2 / 6; }
    &__2-7--small {    grid-column: 2 / 7; }
    &__2-8--small {    grid-column: 2 / 8; }
    &__2-9--small {    grid-column: 2 / 9; }
    &__2-10--small {   grid-column: 2 / 10; }
    &__2-11--small {   grid-column: 2 / 11; }
    &__2-12--small {   grid-column: 2 / 12; }
    &__2-13--small {   grid-column: 2 / 13; }
    &__2-14--small {   grid-column: 2 / 14; }
    &__2-15--small {   grid-column: 2 / 15; }
    &__2-16--small {   grid-column: 2 / 16; }
    &__2-17--small {   grid-column: 2 / 17; }
    &__2-18--small {   grid-column: 2 / 18; }
    &__2-19--small {   grid-column: 2 / 19; }
    &__2-20--small {   grid-column: 2 / 20; }
    &__2-21--small {   grid-column: 2 / 21; }
    // 3
    &__3-4--small {  grid-column: 3 / 4; }
    &__3-5--small {  grid-column: 3 / 5; }
    &__3-6--small {  grid-column: 3 / 6; }
    &__3-7--small {  grid-column: 3 / 7; }
    &__3-8--small {  grid-column: 3 / 8; }
    &__3-9--small {  grid-column: 3 / 9; }
    &__3-10--small { grid-column: 3 / 10; }
    &__3-11--small { grid-column: 3 / 11; }
    &__3-12--small { grid-column: 3 / 12; }
    &__3-13--small { grid-column: 3 / 13; }
    &__3-14--small { grid-column: 3 / 14; }
    &__3-15--small { grid-column: 3 / 15; }
    &__3-16--small { grid-column: 3 / 16; }
    &__3-17--small { grid-column: 3 / 17; }
    &__3-18--small { grid-column: 3 / 18; }
    &__3-19--small { grid-column: 3 / 19; }
    &__3-20--small { grid-column: 3 / 20; }
    &__3-21--small { grid-column: 3 / 21; }
    // 4
    &__4-5--small {  grid-column: 4 / 5; }
    &__4-6--small {  grid-column: 4 / 6; }
    &__4-7--small {  grid-column: 4 / 7; }
    &__4-8--small {  grid-column: 4 / 8; }
    &__4-9--small {  grid-column: 4 / 9; }
    &__4-10--small { grid-column: 4 / 10; }
    &__4-11--small { grid-column: 4 / 11; }
    &__4-12--small { grid-column: 4 / 12; }
    &__4-13--small { grid-column: 4 / 13; }
    &__4-14--small { grid-column: 4 / 14; }
    &__4-15--small { grid-column: 4 / 15; }
    &__4-16--small { grid-column: 4 / 16; }
    &__4-17--small { grid-column: 4 / 17; }
    &__4-18--small { grid-column: 4 / 18; }
    &__4-19--small { grid-column: 4 / 19; }
    &__4-20--small { grid-column: 4 / 20; }
    &__4-21--small { grid-column: 4 / 21; }
    // 5
    &__5-6--small {  grid-column: 5 / 6; }
    &__5-7--small {  grid-column: 5 / 7; }
    &__5-8--small {  grid-column: 5 / 8; }
    &__5-9--small {  grid-column: 5 / 9; }
    &__5-10--small { grid-column: 5 / 10; }
    &__5-11--small { grid-column: 5 / 11; }
    &__5-12--small { grid-column: 5 / 12; }
    &__5-13--small { grid-column: 5 / 13; }
    &__5-14--small { grid-column: 5 / 14; }
    &__5-15--small { grid-column: 5 / 15; }
    &__5-16--small { grid-column: 5 / 16; }
    &__5-17--small { grid-column: 5 / 17; }
    &__5-18--small { grid-column: 5 / 18; }
    &__5-19--small { grid-column: 5 / 19; }
    &__5-20--small { grid-column: 5 / 20; }
    &__5-21--small { grid-column: 5 / 21; }
    // 6
    &__6-7--small {  grid-column: 6 / 7; }
    &__6-8--small {  grid-column: 6 / 8; }
    &__6-9--small {  grid-column: 6 / 9; }
    &__6-10--small { grid-column: 6 / 10; }
    &__6-11--small { grid-column: 6 / 11; }
    &__6-12--small { grid-column: 6 / 12; }
    &__6-13--small { grid-column: 6 / 13; }
    &__6-14--small { grid-column: 6 / 14; }
    &__6-15--small { grid-column: 6 / 15; }
    &__6-16--small { grid-column: 6 / 16; }
    &__6-17--small { grid-column: 6 / 17; }
    &__6-18--small { grid-column: 6 / 18; }
    &__6-19--small { grid-column: 6 / 19; }
    &__6-20--small { grid-column: 6 / 20; }
    &__6-21--small { grid-column: 6 / 21; }
    // 7
    &__7-8--small {  grid-column: 7 / 8; }
    &__7-9--small {  grid-column: 7 / 9; }
    &__7-10--small { grid-column: 7 / 10; }
    &__7-11--small { grid-column: 7 / 11; }
    &__7-12--small { grid-column: 7 / 12; }
    &__7-13--small { grid-column: 7 / 13; }
    &__7-14--small { grid-column: 7 / 14; }
    &__7-15--small { grid-column: 7 / 15; }
    &__7-16--small { grid-column: 7 / 16; }
    &__7-17--small { grid-column: 7 / 17; }
    &__7-18--small { grid-column: 7 / 18; }
    &__7-19--small { grid-column: 7 / 19; }
    &__7-20--small { grid-column: 7 / 20; }
    &__7-21--small { grid-column: 7 / 21; }
    // 8
    &__8-9--small {  grid-column: 8 / 9; }
    &__8-10--small { grid-column: 8 / 10; }
    &__8-11--small { grid-column: 8 / 11; }
    &__8-12--small { grid-column: 8 / 12; }
    &__8-13--small { grid-column: 8 / 13; }
    &__8-14--small { grid-column: 8 / 14; }
    &__8-15--small { grid-column: 8 / 15; }
    &__8-16--small { grid-column: 8 / 16; }
    &__8-17--small { grid-column: 8 / 17; }
    &__8-18--small { grid-column: 8 / 18; }
    &__8-19--small { grid-column: 8 / 19; }
    &__8-20--small { grid-column: 8 / 20; }
    &__8-21--small { grid-column: 8 / 21; }
    // 9
    &__9-10--small { grid-column: 9 / 10; }
    &__9-11--small { grid-column: 9 / 11; }
    &__9-12--small { grid-column: 9 / 12; }
    &__9-13--small { grid-column: 9 / 13; }
    &__9-14--small { grid-column: 9 / 14; }
    &__9-15--small { grid-column: 9 / 15; }
    &__9-16--small { grid-column: 9 / 16; }
    &__9-17--small { grid-column: 9 / 17; }
    &__9-18--small { grid-column: 9 / 18; }
    &__9-19--small { grid-column: 9 / 19; }
    &__9-20--small { grid-column: 9 / 20; }
    &__9-21--small { grid-column: 9 / 21; }
    // 10
    &__10-11--small { grid-column: 10 / 11; }
    &__10-12--small { grid-column: 10 / 12; }
    &__10-13--small { grid-column: 10 / 13; }
    &__10-14--small { grid-column: 10 / 14; }
    &__10-15--small { grid-column: 10 / 15; }
    &__10-16--small { grid-column: 10 / 16; }
    &__10-17--small { grid-column: 10 / 17; }
    &__10-18--small { grid-column: 10 / 18; }
    &__10-19--small { grid-column: 10 / 19; }
    &__10-20--small { grid-column: 10 / 20; }
    &__10-21--small { grid-column: 10 / 21; }
    // 11
    &__11-12--small { grid-column: 11 / 12; }
    &__11-13--small { grid-column: 11 / 13; }
    &__11-14--small { grid-column: 11 / 14; }
    &__11-15--small { grid-column: 11 / 15; }
    &__11-16--small { grid-column: 11 / 16; }
    &__11-17--small { grid-column: 11 / 17; }
    &__11-18--small { grid-column: 11 / 18; }
    &__11-19--small { grid-column: 11 / 19; }
    &__11-20--small { grid-column: 11 / 20; }
    &__11-21--small { grid-column: 11 / 21; }
    // 12
    &__12-13--small { grid-column: 12 / 13; }
    &__12-14--small { grid-column: 12 / 14; }
    &__12-15--small { grid-column: 12 / 15; }
    &__12-16--small { grid-column: 12 / 16; }
    &__12-17--small { grid-column: 12 / 17; }
    &__12-18--small { grid-column: 12 / 18; }
    &__12-19--small { grid-column: 12 / 19; }
    &__12-20--small { grid-column: 12 / 20; }
    &__12-21--small { grid-column: 12 / 21; }
    // 13
    &__13-14--small { grid-column: 13 / 14; }
    &__13-15--small { grid-column: 13 / 15; }
    &__13-16--small { grid-column: 13 / 16; }
    &__13-17--small { grid-column: 13 / 17; }
    &__13-18--small { grid-column: 13 / 18; }
    &__13-19--small { grid-column: 13 / 19; }
    &__13-20--small { grid-column: 13 / 20; }
    &__13-21--small { grid-column: 13 / 21; }
    // 14
    &__14-15--small { grid-column: 14 / 15; }
    &__14-16--small { grid-column: 14 / 16; }
    &__14-17--small { grid-column: 14 / 17; }
    &__14-18--small { grid-column: 14 / 18; }
    &__14-19--small { grid-column: 14 / 19; }
    &__14-20--small { grid-column: 14 / 20; }
    &__14-21--small { grid-column: 14 / 21; }
    // 15
    &__15-16--small { grid-column: 15 / 16; }
    &__15-17--small { grid-column: 15 / 17; }
    &__15-18--small { grid-column: 15 / 18; }
    &__15-19--small { grid-column: 15 / 19; }
    &__15-20--small { grid-column: 15 / 20; }
    &__15-21--small { grid-column: 15 / 21; }
    // 16
    &__16-17--small { grid-column: 16 / 17; }
    &__16-18--small { grid-column: 16 / 18; }
    &__16-19--small { grid-column: 16 / 19; }
    &__16-20--small { grid-column: 16 / 20; }
    &__16-21--small { grid-column: 16 / 21; }
    // 17
    &__17-18--small { grid-column: 17 / 18; }
    &__17-19--small { grid-column: 17 / 19; }
    &__17-20--small { grid-column: 17 / 20; }
    &__17-21--small { grid-column: 17 / 21; }
    // 18
    &__18-19--small { grid-column: 18 / 19; }
    &__18-20--small { grid-column: 18 / 20; }
    &__18-21--small { grid-column: 18 / 21; }
    // 19
    &__19-20--small { grid-column: 19 / 20; }
    &__19-21--small { grid-column: 19 / 21; }
    // 20
    &__20-21--small { grid-column: 20 / 21; }
  }

  @media #{$medium} {
    // 1
    &__1-2--medium {  grid-column: 1 / 2; }
    &__1-3--medium {  grid-column: 1 / 3; }
    &__1-4--medium {  grid-column: 1 / 4; }
    &__1-5--medium {  grid-column: 1 / 5; }
    &__1-6--medium {  grid-column: 1 / 6; }
    &__1-7--medium {  grid-column: 1 / 7; }
    &__1-8--medium {  grid-column: 1 / 8; }
    &__1-9--medium {  grid-column: 1 / 9; }
    &__1-10--medium { grid-column: 1 / 10; }
    &__1-11--medium { grid-column: 1 / 11; }
    &__1-12--medium { grid-column: 1 / 12; }
    &__1-13--medium { grid-column: 1 / 13; }
    &__1-14--medium { grid-column: 1 / 14; }
    &__1-15--medium { grid-column: 1 / 15; }
    &__1-16--medium { grid-column: 1 / 16; }
    &__1-17--medium { grid-column: 1 / 17; }
    &__1-18--medium { grid-column: 1 / 18; }
    &__1-19--medium { grid-column: 1 / 19; }
    &__1-20--medium { grid-column: 1 / 20; }
    &__1-21--medium { grid-column: 1 / 21; }
    // 2
    &__2-3--medium {    grid-column: 2 / 3; }
    &__2-4--medium {    grid-column: 2 / 4; }
    &__2-5--medium {    grid-column: 2 / 5; }
    &__2-6--medium {    grid-column: 2 / 6; }
    &__2-7--medium {    grid-column: 2 / 7; }
    &__2-8--medium {    grid-column: 2 / 8; }
    &__2-9--medium {    grid-column: 2 / 9; }
    &__2-10--medium {   grid-column: 2 / 10; }
    &__2-11--medium {   grid-column: 2 / 11; }
    &__2-12--medium {   grid-column: 2 / 12; }
    &__2-13--medium {   grid-column: 2 / 13; }
    &__2-14--medium {   grid-column: 2 / 14; }
    &__2-15--medium {   grid-column: 2 / 15; }
    &__2-16--medium {   grid-column: 2 / 16; }
    &__2-17--medium {   grid-column: 2 / 17; }
    &__2-18--medium {   grid-column: 2 / 18; }
    &__2-19--medium {   grid-column: 2 / 19; }
    &__2-20--medium {   grid-column: 2 / 20; }
    &__2-21--medium {   grid-column: 2 / 21; }
    // 3
    &__3-4--medium {  grid-column: 3 / 4; }
    &__3-5--medium {  grid-column: 3 / 5; }
    &__3-6--medium {  grid-column: 3 / 6; }
    &__3-7--medium {  grid-column: 3 / 7; }
    &__3-8--medium {  grid-column: 3 / 8; }
    &__3-9--medium {  grid-column: 3 / 9; }
    &__3-10--medium { grid-column: 3 / 10; }
    &__3-11--medium { grid-column: 3 / 11; }
    &__3-12--medium { grid-column: 3 / 12; }
    &__3-13--medium { grid-column: 3 / 13; }
    &__3-14--medium { grid-column: 3 / 14; }
    &__3-15--medium { grid-column: 3 / 15; }
    &__3-16--medium { grid-column: 3 / 16; }
    &__3-17--medium { grid-column: 3 / 17; }
    &__3-18--medium { grid-column: 3 / 18; }
    &__3-19--medium { grid-column: 3 / 19; }
    &__3-20--medium { grid-column: 3 / 20; }
    &__3-21--medium { grid-column: 3 / 21; }
    // 4
    &__4-5--medium {  grid-column: 4 / 5; }
    &__4-6--medium {  grid-column: 4 / 6; }
    &__4-7--medium {  grid-column: 4 / 7; }
    &__4-8--medium {  grid-column: 4 / 8; }
    &__4-9--medium {  grid-column: 4 / 9; }
    &__4-10--medium { grid-column: 4 / 10; }
    &__4-11--medium { grid-column: 4 / 11; }
    &__4-12--medium { grid-column: 4 / 12; }
    &__4-13--medium { grid-column: 4 / 13; }
    &__4-14--medium { grid-column: 4 / 14; }
    &__4-15--medium { grid-column: 4 / 15; }
    &__4-16--medium { grid-column: 4 / 16; }
    &__4-17--medium { grid-column: 4 / 17; }
    &__4-18--medium { grid-column: 4 / 18; }
    &__4-19--medium { grid-column: 4 / 19; }
    &__4-20--medium { grid-column: 4 / 20; }
    &__4-21--medium { grid-column: 4 / 21; }
    // 5
    &__5-6--medium {  grid-column: 5 / 6; }
    &__5-7--medium {  grid-column: 5 / 7; }
    &__5-8--medium {  grid-column: 5 / 8; }
    &__5-9--medium {  grid-column: 5 / 9; }
    &__5-10--medium { grid-column: 5 / 10; }
    &__5-11--medium { grid-column: 5 / 11; }
    &__5-12--medium { grid-column: 5 / 12; }
    &__5-13--medium { grid-column: 5 / 13; }
    &__5-14--medium { grid-column: 5 / 14; }
    &__5-15--medium { grid-column: 5 / 15; }
    &__5-16--medium { grid-column: 5 / 16; }
    &__5-17--medium { grid-column: 5 / 17; }
    &__5-18--medium { grid-column: 5 / 18; }
    &__5-19--medium { grid-column: 5 / 19; }
    &__5-20--medium { grid-column: 5 / 20; }
    &__5-21--medium { grid-column: 5 / 21; }
    // 6
    &__6-7--medium {  grid-column: 6 / 7; }
    &__6-8--medium {  grid-column: 6 / 8; }
    &__6-9--medium {  grid-column: 6 / 9; }
    &__6-10--medium { grid-column: 6 / 10; }
    &__6-11--medium { grid-column: 6 / 11; }
    &__6-12--medium { grid-column: 6 / 12; }
    &__6-13--medium { grid-column: 6 / 13; }
    &__6-14--medium { grid-column: 6 / 14; }
    &__6-15--medium { grid-column: 6 / 15; }
    &__6-16--medium { grid-column: 6 / 16; }
    &__6-17--medium { grid-column: 6 / 17; }
    &__6-18--medium { grid-column: 6 / 18; }
    &__6-19--medium { grid-column: 6 / 19; }
    &__6-20--medium { grid-column: 6 / 20; }
    &__6-21--medium { grid-column: 6 / 21; }
    // 7
    &__7-8--medium {  grid-column: 7 / 8; }
    &__7-9--medium {  grid-column: 7 / 9; }
    &__7-10--medium { grid-column: 7 / 10; }
    &__7-11--medium { grid-column: 7 / 11; }
    &__7-12--medium { grid-column: 7 / 12; }
    &__7-13--medium { grid-column: 7 / 13; }
    &__7-14--medium { grid-column: 7 / 14; }
    &__7-15--medium { grid-column: 7 / 15; }
    &__7-16--medium { grid-column: 7 / 16; }
    &__7-17--medium { grid-column: 7 / 17; }
    &__7-18--medium { grid-column: 7 / 18; }
    &__7-19--medium { grid-column: 7 / 19; }
    &__7-20--medium { grid-column: 7 / 20; }
    &__7-21--medium { grid-column: 7 / 21; }
    // 8
    &__8-9--medium {  grid-column: 8 / 9; }
    &__8-10--medium { grid-column: 8 / 10; }
    &__8-11--medium { grid-column: 8 / 11; }
    &__8-12--medium { grid-column: 8 / 12; }
    &__8-13--medium { grid-column: 8 / 13; }
    &__8-14--medium { grid-column: 8 / 14; }
    &__8-15--medium { grid-column: 8 / 15; }
    &__8-16--medium { grid-column: 8 / 16; }
    &__8-17--medium { grid-column: 8 / 17; }
    &__8-18--medium { grid-column: 8 / 18; }
    &__8-19--medium { grid-column: 8 / 19; }
    &__8-20--medium { grid-column: 8 / 20; }
    &__8-21--medium { grid-column: 8 / 21; }
    // 9
    &__9-10--medium { grid-column: 9 / 10; }
    &__9-11--medium { grid-column: 9 / 11; }
    &__9-12--medium { grid-column: 9 / 12; }
    &__9-13--medium { grid-column: 9 / 13; }
    &__9-14--medium { grid-column: 9 / 14; }
    &__9-15--medium { grid-column: 9 / 15; }
    &__9-16--medium { grid-column: 9 / 16; }
    &__9-17--medium { grid-column: 9 / 17; }
    &__9-18--medium { grid-column: 9 / 18; }
    &__9-19--medium { grid-column: 9 / 19; }
    &__9-20--medium { grid-column: 9 / 20; }
    &__9-21--medium { grid-column: 9 / 21; }
    // 10
    &__10-11--medium { grid-column: 10 / 11; }
    &__10-12--medium { grid-column: 10 / 12; }
    &__10-13--medium { grid-column: 10 / 13; }
    &__10-14--medium { grid-column: 10 / 14; }
    &__10-15--medium { grid-column: 10 / 15; }
    &__10-16--medium { grid-column: 10 / 16; }
    &__10-17--medium { grid-column: 10 / 17; }
    &__10-18--medium { grid-column: 10 / 18; }
    &__10-19--medium { grid-column: 10 / 19; }
    &__10-20--medium { grid-column: 10 / 20; }
    &__10-21--medium { grid-column: 10 / 21; }
    // 11
    &__11-12--medium { grid-column: 11 / 12; }
    &__11-13--medium { grid-column: 11 / 13; }
    &__11-14--medium { grid-column: 11 / 14; }
    &__11-15--medium { grid-column: 11 / 15; }
    &__11-16--medium { grid-column: 11 / 16; }
    &__11-17--medium { grid-column: 11 / 17; }
    &__11-18--medium { grid-column: 11 / 18; }
    &__11-19--medium { grid-column: 11 / 19; }
    &__11-20--medium { grid-column: 11 / 20; }
    &__11-21--medium { grid-column: 11 / 21; }
    // 12
    &__12-13--medium { grid-column: 12 / 13; }
    &__12-14--medium { grid-column: 12 / 14; }
    &__12-15--medium { grid-column: 12 / 15; }
    &__12-16--medium { grid-column: 12 / 16; }
    &__12-17--medium { grid-column: 12 / 17; }
    &__12-18--medium { grid-column: 12 / 18; }
    &__12-19--medium { grid-column: 12 / 19; }
    &__12-20--medium { grid-column: 12 / 20; }
    &__12-21--medium { grid-column: 12 / 21; }
    // 13
    &__13-14--medium { grid-column: 13 / 14; }
    &__13-15--medium { grid-column: 13 / 15; }
    &__13-16--medium { grid-column: 13 / 16; }
    &__13-17--medium { grid-column: 13 / 17; }
    &__13-18--medium { grid-column: 13 / 18; }
    &__13-19--medium { grid-column: 13 / 19; }
    &__13-20--medium { grid-column: 13 / 20; }
    &__13-21--medium { grid-column: 13 / 21; }
    // 14
    &__14-15--medium { grid-column: 14 / 15; }
    &__14-16--medium { grid-column: 14 / 16; }
    &__14-17--medium { grid-column: 14 / 17; }
    &__14-18--medium { grid-column: 14 / 18; }
    &__14-19--medium { grid-column: 14 / 19; }
    &__14-20--medium { grid-column: 14 / 20; }
    &__14-21--medium { grid-column: 14 / 21; }
    // 15
    &__15-16--medium { grid-column: 15 / 16; }
    &__15-17--medium { grid-column: 15 / 17; }
    &__15-18--medium { grid-column: 15 / 18; }
    &__15-19--medium { grid-column: 15 / 19; }
    &__15-20--medium { grid-column: 15 / 20; }
    &__15-21--medium { grid-column: 15 / 21; }
    // 16
    &__16-17--medium { grid-column: 16 / 17; }
    &__16-18--medium { grid-column: 16 / 18; }
    &__16-19--medium { grid-column: 16 / 19; }
    &__16-20--medium { grid-column: 16 / 20; }
    &__16-21--medium { grid-column: 16 / 21; }
    // 17
    &__17-18--medium { grid-column: 17 / 18; }
    &__17-19--medium { grid-column: 17 / 19; }
    &__17-20--medium { grid-column: 17 / 20; }
    &__17-21--medium { grid-column: 17 / 21; }
    // 18
    &__18-19--medium { grid-column: 18 / 19; }
    &__18-20--medium { grid-column: 18 / 20; }
    &__18-21--medium { grid-column: 18 / 21; }
    // 19
    &__19-20--medium { grid-column: 19 / 20; }
    &__19-21--medium { grid-column: 19 / 21; }
    // 20
    &__20-21--medium { grid-column: 20 / 21; }
  }

  @media #{$large} {
    // 1
    &__1-2--large {  grid-column: 1 / 2; }
    &__1-3--large {  grid-column: 1 / 3; }
    &__1-4--large {  grid-column: 1 / 4; }
    &__1-5--large {  grid-column: 1 / 5; }
    &__1-6--large {  grid-column: 1 / 6; }
    &__1-7--large {  grid-column: 1 / 7; }
    &__1-8--large {  grid-column: 1 / 8; }
    &__1-9--large {  grid-column: 1 / 9; }
    &__1-10--large { grid-column: 1 / 10; }
    &__1-11--large { grid-column: 1 / 11; }
    &__1-12--large { grid-column: 1 / 12; }
    &__1-13--large { grid-column: 1 / 13; }
    &__1-14--large { grid-column: 1 / 14; }
    &__1-15--large { grid-column: 1 / 15; }
    &__1-16--large { grid-column: 1 / 16; }
    &__1-17--large { grid-column: 1 / 17; }
    &__1-18--large { grid-column: 1 / 18; }
    &__1-19--large { grid-column: 1 / 19; }
    &__1-20--large { grid-column: 1 / 20; }
    &__1-21--large { grid-column: 1 / 21; }
    // 2
    &__2-3--large {    grid-column: 2 / 3; }
    &__2-4--large {    grid-column: 2 / 4; }
    &__2-5--large {    grid-column: 2 / 5; }
    &__2-6--large {    grid-column: 2 / 6; }
    &__2-7--large {    grid-column: 2 / 7; }
    &__2-8--large {    grid-column: 2 / 8; }
    &__2-9--large {    grid-column: 2 / 9; }
    &__2-10--large {   grid-column: 2 / 10; }
    &__2-11--large {   grid-column: 2 / 11; }
    &__2-12--large {   grid-column: 2 / 12; }
    &__2-13--large {   grid-column: 2 / 13; }
    &__2-14--large {   grid-column: 2 / 14; }
    &__2-15--large {   grid-column: 2 / 15; }
    &__2-16--large {   grid-column: 2 / 16; }
    &__2-17--large {   grid-column: 2 / 17; }
    &__2-18--large {   grid-column: 2 / 18; }
    &__2-19--large {   grid-column: 2 / 19; }
    &__2-20--large {   grid-column: 2 / 20; }
    &__2-21--large {   grid-column: 2 / 21; }
    // 3
    &__3-4--large {  grid-column: 3 / 4; }
    &__3-5--large {  grid-column: 3 / 5; }
    &__3-6--large {  grid-column: 3 / 6; }
    &__3-7--large {  grid-column: 3 / 7; }
    &__3-8--large {  grid-column: 3 / 8; }
    &__3-9--large {  grid-column: 3 / 9; }
    &__3-10--large { grid-column: 3 / 10; }
    &__3-11--large { grid-column: 3 / 11; }
    &__3-12--large { grid-column: 3 / 12; }
    &__3-13--large { grid-column: 3 / 13; }
    &__3-14--large { grid-column: 3 / 14; }
    &__3-15--large { grid-column: 3 / 15; }
    &__3-16--large { grid-column: 3 / 16; }
    &__3-17--large { grid-column: 3 / 17; }
    &__3-18--large { grid-column: 3 / 18; }
    &__3-19--large { grid-column: 3 / 19; }
    &__3-20--large { grid-column: 3 / 20; }
    &__3-21--large { grid-column: 3 / 21; }
    // 4
    &__4-5--large {  grid-column: 4 / 5; }
    &__4-6--large {  grid-column: 4 / 6; }
    &__4-7--large {  grid-column: 4 / 7; }
    &__4-8--large {  grid-column: 4 / 8; }
    &__4-9--large {  grid-column: 4 / 9; }
    &__4-10--large { grid-column: 4 / 10; }
    &__4-11--large { grid-column: 4 / 11; }
    &__4-12--large { grid-column: 4 / 12; }
    &__4-13--large { grid-column: 4 / 13; }
    &__4-14--large { grid-column: 4 / 14; }
    &__4-15--large { grid-column: 4 / 15; }
    &__4-16--large { grid-column: 4 / 16; }
    &__4-17--large { grid-column: 4 / 17; }
    &__4-18--large { grid-column: 4 / 18; }
    &__4-19--large { grid-column: 4 / 19; }
    &__4-20--large { grid-column: 4 / 20; }
    &__4-21--large { grid-column: 4 / 21; }
    // 5
    &__5-6--large {  grid-column: 5 / 6; }
    &__5-7--large {  grid-column: 5 / 7; }
    &__5-8--large {  grid-column: 5 / 8; }
    &__5-9--large {  grid-column: 5 / 9; }
    &__5-10--large { grid-column: 5 / 10; }
    &__5-11--large { grid-column: 5 / 11; }
    &__5-12--large { grid-column: 5 / 12; }
    &__5-13--large { grid-column: 5 / 13; }
    &__5-14--large { grid-column: 5 / 14; }
    &__5-15--large { grid-column: 5 / 15; }
    &__5-16--large { grid-column: 5 / 16; }
    &__5-17--large { grid-column: 5 / 17; }
    &__5-18--large { grid-column: 5 / 18; }
    &__5-19--large { grid-column: 5 / 19; }
    &__5-20--large { grid-column: 5 / 20; }
    &__5-21--large { grid-column: 5 / 21; }
    // 6
    &__6-7--large {  grid-column: 6 / 7; }
    &__6-8--large {  grid-column: 6 / 8; }
    &__6-9--large {  grid-column: 6 / 9; }
    &__6-10--large { grid-column: 6 / 10; }
    &__6-11--large { grid-column: 6 / 11; }
    &__6-12--large { grid-column: 6 / 12; }
    &__6-13--large { grid-column: 6 / 13; }
    &__6-14--large { grid-column: 6 / 14; }
    &__6-15--large { grid-column: 6 / 15; }
    &__6-16--large { grid-column: 6 / 16; }
    &__6-17--large { grid-column: 6 / 17; }
    &__6-18--large { grid-column: 6 / 18; }
    &__6-19--large { grid-column: 6 / 19; }
    &__6-20--large { grid-column: 6 / 20; }
    &__6-21--large { grid-column: 6 / 21; }
    // 7
    &__7-8--large {  grid-column: 7 / 8; }
    &__7-9--large {  grid-column: 7 / 9; }
    &__7-10--large { grid-column: 7 / 10; }
    &__7-11--large { grid-column: 7 / 11; }
    &__7-12--large { grid-column: 7 / 12; }
    &__7-13--large { grid-column: 7 / 13; }
    &__7-14--large { grid-column: 7 / 14; }
    &__7-15--large { grid-column: 7 / 15; }
    &__7-16--large { grid-column: 7 / 16; }
    &__7-17--large { grid-column: 7 / 17; }
    &__7-18--large { grid-column: 7 / 18; }
    &__7-19--large { grid-column: 7 / 19; }
    &__7-20--large { grid-column: 7 / 20; }
    &__7-21--large { grid-column: 7 / 21; }
    // 8
    &__8-9--large {  grid-column: 8 / 9; }
    &__8-10--large { grid-column: 8 / 10; }
    &__8-11--large { grid-column: 8 / 11; }
    &__8-12--large { grid-column: 8 / 12; }
    &__8-13--large { grid-column: 8 / 13; }
    &__8-14--large { grid-column: 8 / 14; }
    &__8-15--large { grid-column: 8 / 15; }
    &__8-16--large { grid-column: 8 / 16; }
    &__8-17--large { grid-column: 8 / 17; }
    &__8-18--large { grid-column: 8 / 18; }
    &__8-19--large { grid-column: 8 / 19; }
    &__8-20--large { grid-column: 8 / 20; }
    &__8-21--large { grid-column: 8 / 21; }
    // 9
    &__9-10--large { grid-column: 9 / 10; }
    &__9-11--large { grid-column: 9 / 11; }
    &__9-12--large { grid-column: 9 / 12; }
    &__9-13--large { grid-column: 9 / 13; }
    &__9-14--large { grid-column: 9 / 14; }
    &__9-15--large { grid-column: 9 / 15; }
    &__9-16--large { grid-column: 9 / 16; }
    &__9-17--large { grid-column: 9 / 17; }
    &__9-18--large { grid-column: 9 / 18; }
    &__9-19--large { grid-column: 9 / 19; }
    &__9-20--large { grid-column: 9 / 20; }
    &__9-21--large { grid-column: 9 / 21; }
    // 10
    &__10-11--large { grid-column: 10 / 11; }
    &__10-12--large { grid-column: 10 / 12; }
    &__10-13--large { grid-column: 10 / 13; }
    &__10-14--large { grid-column: 10 / 14; }
    &__10-15--large { grid-column: 10 / 15; }
    &__10-16--large { grid-column: 10 / 16; }
    &__10-17--large { grid-column: 10 / 17; }
    &__10-18--large { grid-column: 10 / 18; }
    &__10-19--large { grid-column: 10 / 19; }
    &__10-20--large { grid-column: 10 / 20; }
    &__10-21--large { grid-column: 10 / 21; }
    // 11
    &__11-12--large { grid-column: 11 / 12; }
    &__11-13--large { grid-column: 11 / 13; }
    &__11-14--large { grid-column: 11 / 14; }
    &__11-15--large { grid-column: 11 / 15; }
    &__11-16--large { grid-column: 11 / 16; }
    &__11-17--large { grid-column: 11 / 17; }
    &__11-18--large { grid-column: 11 / 18; }
    &__11-19--large { grid-column: 11 / 19; }
    &__11-20--large { grid-column: 11 / 20; }
    &__11-21--large { grid-column: 11 / 21; }
    // 12
    &__12-13--large { grid-column: 12 / 13; }
    &__12-14--large { grid-column: 12 / 14; }
    &__12-15--large { grid-column: 12 / 15; }
    &__12-16--large { grid-column: 12 / 16; }
    &__12-17--large { grid-column: 12 / 17; }
    &__12-18--large { grid-column: 12 / 18; }
    &__12-19--large { grid-column: 12 / 19; }
    &__12-20--large { grid-column: 12 / 20; }
    &__12-21--large { grid-column: 12 / 21; }
    // 13
    &__13-14--large { grid-column: 13 / 14; }
    &__13-15--large { grid-column: 13 / 15; }
    &__13-16--large { grid-column: 13 / 16; }
    &__13-17--large { grid-column: 13 / 17; }
    &__13-18--large { grid-column: 13 / 18; }
    &__13-19--large { grid-column: 13 / 19; }
    &__13-20--large { grid-column: 13 / 20; }
    &__13-21--large { grid-column: 13 / 21; }
    // 14
    &__14-15--large { grid-column: 14 / 15; }
    &__14-16--large { grid-column: 14 / 16; }
    &__14-17--large { grid-column: 14 / 17; }
    &__14-18--large { grid-column: 14 / 18; }
    &__14-19--large { grid-column: 14 / 19; }
    &__14-20--large { grid-column: 14 / 20; }
    &__14-21--large { grid-column: 14 / 21; }
    // 15
    &__15-16--large { grid-column: 15 / 16; }
    &__15-17--large { grid-column: 15 / 17; }
    &__15-18--large { grid-column: 15 / 18; }
    &__15-19--large { grid-column: 15 / 19; }
    &__15-20--large { grid-column: 15 / 20; }
    &__15-21--large { grid-column: 15 / 21; }
    // 16
    &__16-17--large { grid-column: 16 / 17; }
    &__16-18--large { grid-column: 16 / 18; }
    &__16-19--large { grid-column: 16 / 19; }
    &__16-20--large { grid-column: 16 / 20; }
    &__16-21--large { grid-column: 16 / 21; }
    // 17
    &__17-18--large { grid-column: 17 / 18; }
    &__17-19--large { grid-column: 17 / 19; }
    &__17-20--large { grid-column: 17 / 20; }
    &__17-21--large { grid-column: 17 / 21; }
    // 18
    &__18-19--large { grid-column: 18 / 19; }
    &__18-20--large { grid-column: 18 / 20; }
    &__18-21--large { grid-column: 18 / 21; }
    // 19
    &__19-20--large { grid-column: 19 / 20; }
    &__19-21--large { grid-column: 19 / 21; }
    // 20
    &__20-21--large { grid-column: 20 / 21; }
  }

  &__mod-cs-0 {
    margin: 7vmin 0;
    @media #{$small} {
      grid-template-rows: 72px auto 24px;
    }
  }
  &__mod-cs-1 {
    @media #{$small} {
      margin: 8vmin 0 8vmin;
      //grid-template-rows: auto auto 1fr 1fr auto;
    }
    @media #{$medium} {
      margin: 10vmin 0 8vmin;
    }
    &:after {
      @media #{$small} {
        content: "";
        background: #EFD187;
        width: 100%;
        height: 100%;
        min-height: 200px;
        z-index: -1;
        grid-area: 3 / 4 / 4 / 21;
        position: relative;
        top: -1px;
      }
      @media #{$medium} {
        //right: -5vw;
        grid-area: 3 / 4 / 4 / 21;
      }
    }
  }
  &__mod-cs-2 {
    margin-bottom: 20vmin;
    @media #{$small} {
      grid-template-rows: auto 36px auto;
    }
  }
  &__mod-cs-3 {
    grid-column-gap: 8px;
    grid-row-gap: 8px;
    @media #{$small} {
      //grid-template-rows: auto 122px auto auto;
      grid-column-gap: 12px;
      grid-row-gap: 12px;
    }
    @media #{$medium} {
      //grid-template-rows: auto 122px auto auto;
      grid-column-gap: 20px;
      grid-row-gap: 20px;
    }
  }
  &__mod-cs-4 {
    @media #{$small} {
      grid-template-rows: auto auto;
    }
  }
}

.flx {
  display: flex;
  flex-wrap: wrap;
  &-ai { 
    &--center { align-items: center; }
    &--start { align-items: flex-start; }
    &--end { align-items: flex-end; }
  }

  &-jc { 
    &--center { justify-content: center; }
    &--between { justify-content: space-between; }
    &--start { justify-content: flex-start; }
    &--end { justify-content: flex-end; }
  }
}

.as {
  &--center {
    align-self: center;
  }
}

.mod {
  &-cs-0 {
    &__main {
      background: #F3F5F5;
      position: relative;
      padding: 72px 5vw;
      @media #{$small} {
        grid-row: 1 / 3;
      }
      @media #{$medium} {
        grid-row: 1 / 3;
        background: #F3F5F5;
        position: relative;
        padding: 72px 10vw;
      }
      &:before {
        display: block;
        width: 50%;
        height: 6px;
        background: #FFD260;
        position: absolute;
        content: '';
        top: 0;
        left: 0;
      }
      &:after {
        display: block;
        background: #F3F5F5;
        content: '';
        position: absolute;
        top: 0;
        left: 100%;
        height: 100%; 
        width: 5vw;
        @media #{$medium} {
          width: 20vw;
        }
      }
      h3 {
        margin: 0 0 1em!important;
      }
      li {

      }
    }
    &__aside {
      background: #3F5B5C;
      position: relative;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      align-content: center;
      padding: 48px 5vw;
      top: -48px;
      @media #{$small} {
        grid-row: 2 / 4;
        padding: 72px 5vw;
      }
      & > * {
        flex: 0 0 auto;
        width: 100%;
      }
      p:last-child {
        margin-bottom: 0!important;
      }
      div {

      }
      h3 {
        margin: 0 0 1em!important;
      }
      p {
        em {
          color: #FFD260;
          font-style: normal;
        }
      }
    }
  }
  &-cs-1 {
    &__dek {
      @media #{$small} {
        grid-row: 1 / 2;
        position: relative;
      }
      @media #{$medium} {
        grid-row: 1 / 3;
      }
    }
    &__feat {
      padding: 12vmin 5vw 72px;
      @media #{$small} {
        grid-row: 2 / 3;
        position: relative;
        padding: 5vmin 7.5vw 60px 5vw;
        align-self: start;
        z-index: 9;
      }
      @media #{$medium} {
        grid-row: 3 / 4;
        position: relative;
        padding: 5vmin 12.5vw 60px 5vw;
      }
    }
    &__fig {
      @media #{$small} {
        grid-row: 2 / 3;
        position: relative;
        align-self: end;
      }
      @media #{$medium} {
        grid-row: 2 / 3;
      }
    }
    &__action {
      @media #{$small} {
        position: relative;
        grid-row: 3 / 5;
        z-index: 9;
        min-width: 0;
        align-self: start;
      }
      @media #{$medium} {
        position: relative;
        left: -5vw;
        grid-row: 3 / 5;
        z-index: 9;
        align-self: end;
        padding-top: 6rem;
      }
      .emails {
        @media #{$small} {
          position: relative;
          background: #FFFFFF;
          box-shadow: 0px 10px 20px rgba(0,0,0,0.15);
        }
      }
    }
  }
  &-cs-2 {
    &__hdr {
      @media #{$small} {
        grid-row: 1 / 2;
        position: relative;
        padding: 3rem 0 4rem;
      }
      @media #{$medium} {
        grid-row: 1 / 2;
        position: relative;
        padding: 7rem 0 8rem;
      }
    }
    &__fig {
      @media #{$small} {
        grid-row: 1 / 3;
        position: relative;
        z-index: 9;
      }
      @media #{$medium} {
        grid-row: 1 / 3;
        position: relative;
        z-index: 9;
      }
      figure {
        height: 100%;
      }
      img {
        object-fit: cover;
        height: 100%!important;
      }
    }
    &__feat {
      padding: 0rem 5vw 2rem;
      @media #{$small} {
        padding: 3rem 5vw 4rem;
        grid-row: 2 / 4;
        position: relative;
      }
      @media #{$small} {
      }
      &:before {
        @media #{$small} {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 50%;
          height: 6px;
          background: #FFD260;
        }
      }
      ul {
        margin: 0!important;
        padding: 0!important;
        list-style-type: none;
        @media #{$small} {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }
      }
      li {
        margin: 0!important;
        padding: 0!important;
        @media #{$small} {
          flex: 1 0 auto;
          padding-right: 1.5rem!important;
          width: 50%;
        }
        @media #{$large} {
          flex: 0 0 auto;
          width: 33.3%
        }
      }
      p {
        margin: 0;
      }
    }
  }
  &-cs-3 {
    &__fig {
      min-height: 40vmin;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      padding: 1.5vw 2vw;
      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 8;
      }
    }
    @media #{$small} {
      &__fig-1 { 
        grid-row: 1 / 3; 
      }
      &__fig-8 { grid-row: 4 / 6; }
    }
    @media #{$medium} {
      &__fig-1 { grid-row: 1 / 3; }
      &__fig-8 { grid-row: 4 / 6; }
    }
  }
  &-cs-4 {
    &__main {
      @media #{$small} {
        grid-row: 1 / 2;
      }
    }
    &__fig {
      @media #{$small} {
        grid-row: 1 / 2;
      }
    }
  }
  &-cs-5 {
    &__1 {
      @media #{$small} {
        grid-row: 1 / 2;
      }
    }
    &__2 {
      @media #{$small} {
        grid-row: 2 / 3;
      }
    }
    &__fig {
      @media #{$small} {
        grid-row: 2 / 3;
      }
      @media #{$large} {
        grid-row: 1 / 3;
      }
    }
  }
}