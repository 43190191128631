$PRIMARY_COLOR: #264344;
$FOOTER_BKGD_COLOR: #F6F7F7; 

$HEADING_FONT: "urw-din-semi-condensed", sans-serif;
$COPY_FONT: "pt-serif", serif;

$xxsmall: "only screen and (min-width: 400px)"; //400px
$xsmall: "only screen and (min-width: 600px)"; //600px
$small: "only screen and (min-width: 48em)"; // 768px
$medium: "only screen and (min-width: 64em)"; // 1024px
$large: "only screen and (min-width: 80em)"; // 1280px
$xlarge: "only screen and (min-width: 90em)"; // 1280px

@function calculate-column-width($some-number){
    @return $some-number * 6.25%; 
}

